import { FormApi } from "final-form";
import { Field } from "react-final-form";
import styled, { css, down } from "@xstyled/styled-components";

import { DatesInput } from "../../../components/DatesInput";
import { FormStages } from "../index";

import { EditedWorkExperienceItem } from "./FormCard";
import { WorkExperienceItem } from "./FormCardList";

import { pxToRem } from "@otta/design-tokens";
import { InputField, Spacing, Text, Label } from "@otta/design";
import { required, validUrl } from "@otta/search/utils/validators";
import { ImagePreview } from "@otta/search/components/ImagePreview";
import { BulletTextarea } from "@otta/search/components/BulletTextarea";
import { HalfWidth } from "@otta/search/pages/Profile/components/FormData";
import { ImageUploadField } from "@otta/search/components/ImageUploadField";
import {
  ImageCategory,
  SectorTagsDocument,
  TechnologiesUsedDocument,
} from "@otta/search/schema";
import { PassiveMultiSelectField as MultiSelectField } from "@otta/search/components/Input/PassiveMultiSelectField";
import { RightArrow } from "@otta/search/components/Icons/RightArrow";

const LabelText = styled.div`
  color: gray-600;
`;

const BoldText = styled.strong`
  color: black;
  font-weight: bold;
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
`;

const BreadcrumbsWrapper = styled(RowWrapper)`
  align-items: center;
`;

const BreadcrumbsText = styled(Text)<{ isCurrent: boolean }>`
  font-weight: ${({ isCurrent }) => isCurrent && "bold"};
  text-decoration: ${({ isCurrent }) => isCurrent && "underline"};
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${pxToRem(7.33)};
  height: ${pxToRem(13.33)};
  margin: 0 sm;

  svg {
    stroke: gray-400;
  }
`;

const DescriptionText = styled(Text)`
  color: gray-600;
`;

const TabletText = styled(Text)`
  ${down(
    "tablet",
    css`
      display: none;
    `
  )}
`;

function Breadcrumbs({
  currentStage,
}: {
  currentStage: FormStages | undefined;
}): React.ReactElement {
  return (
    <BreadcrumbsWrapper>
      <BreadcrumbsText
        size={-1}
        isCurrent={currentStage == FormStages.Overview}
      >
        Overview
      </BreadcrumbsText>
      <IconWrapper>
        <RightArrow />
      </IconWrapper>
      <BreadcrumbsText
        size={-1}
        isCurrent={currentStage == FormStages.RoleDescription}
      >
        Role<TabletText as="span"> description</TabletText>
      </BreadcrumbsText>
      <IconWrapper>
        <RightArrow />
      </IconWrapper>
      <BreadcrumbsText
        size={-1}
        isCurrent={currentStage == FormStages.CompanyDescription}
      >
        Company<TabletText as="span"> description</TabletText>
      </BreadcrumbsText>
    </BreadcrumbsWrapper>
  );
}

function OverviewStage({
  form,
  experience,
}: {
  form: FormApi<EditedWorkExperienceItem, Partial<EditedWorkExperienceItem>>;
  experience: WorkExperienceItem | null;
}): React.ReactElement {
  return (
    <Spacing>
      <Field
        name="title"
        validate={required}
        render={({ input, meta }) => (
          <HalfWidth>
            <InputField
              {...input}
              type="text"
              label="Role title"
              placeholder="Role title"
              error={meta.touched && meta.error}
            />
          </HalfWidth>
        )}
      />
      <Field
        name="companyName"
        validate={required}
        render={({ input, meta }) => (
          <HalfWidth>
            <InputField
              {...input}
              type="text"
              label="Company name"
              placeholder="Company name"
              error={meta.touched && meta.error}
            />
          </HalfWidth>
        )}
      />
      <HalfWidth>
        <DatesInput
          startDateName="startDate"
          endDateName="endDate"
          showEndDate={experience?.endDate !== null}
          checkboxLabel="I'm currently working in this role"
          clearEndDate={() => form.change("endDate", null)}
          setEndDateToOriginal={() =>
            form.change("endDate", experience?.endDate)
          }
        />
      </HalfWidth>
    </Spacing>
  );
}

function RoleDescriptionStage(): React.ReactElement {
  return (
    <Spacing>
      <BulletTextarea
        label="Description"
        hint="Write at least 3 bullet points describing your role. Focus on your achievements and help recruiters understand the context of the company."
        fieldName="descriptions"
        placeholders={[
          "Joined as the 3rd software engineer and developed the engineering function...",
          "Built experiments that grew yearly revenue by 10%...",
          "Launched a new feature which increased conversion by 23%...",
        ]}
      />
      <Field<{ id: string; value: string }[]>
        name="technologiesUsed"
        format={val => val}
        render={({ input: { value, onChange } }) => (
          <MultiSelectField
            id="technologies"
            label="Technologies used"
            optionsQuery={TechnologiesUsedDocument}
            optionsFieldName="technologiesUsed"
            limit={8}
            placeholder="Type technologies..."
            items={value}
            handleReturn={onChange}
          />
        )}
      />
    </Spacing>
  );
}

function CompanyDescriptionStage(): React.ReactElement {
  return (
    <Spacing>
      <Field
        name="companyDescription"
        render={({ input, meta }) => (
          <Spacing>
            <Spacing size={-2}>
              <RowWrapper>
                <LabelText>
                  <BoldText>Short description of the company</BoldText>{" "}
                  (optional)
                </LabelText>
              </RowWrapper>
              <DescriptionText>
                Write 5-10 words explaining what the company does.
              </DescriptionText>
            </Spacing>
            <InputField
              {...input}
              type="text"
              placeholder="e.g. List, discover and book unique spaces around the world"
              maxLength={60}
              error={meta.touched && meta.error}
            />
          </Spacing>
        )}
      />
      <Field<{ id: string; value: string }[]>
        name="sectorTags"
        format={val => val}
        render={({ input: { value, onChange } }) => (
          <Spacing>
            <Spacing size={-2}>
              <RowWrapper>
                <LabelText>
                  <BoldText>Relevant industries</BoldText> (optional)
                </LabelText>
              </RowWrapper>
              <DescriptionText>
                Select industries relevant to this role from the list or type
                them in below.
              </DescriptionText>
            </Spacing>
            <MultiSelectField
              id="sectorTags"
              optionsQuery={SectorTagsDocument}
              optionsFieldName="sectorTags"
              limit={8}
              placeholder="Type industries..."
              items={value}
              handleReturn={onChange}
            />
          </Spacing>
        )}
      />
      <Field
        data-cs-mask
        name="companyUrl"
        validate={validUrl}
        render={({ input, meta }) => (
          <Spacing>
            <Spacing size={-2}>
              <RowWrapper>
                <LabelText>
                  <BoldText>Company website</BoldText> (optional)
                </LabelText>
              </RowWrapper>
            </Spacing>
            <HalfWidth>
              <InputField
                {...input}
                type="text"
                placeholder="https://www.company.com"
                error={meta.touched && meta.error}
              />
            </HalfWidth>
          </Spacing>
        )}
      />
      <Field name="companyLogoPath">
        {({ input, meta }) => (
          <Spacing size={-5}>
            <Label>Company square logo</Label>
            <ImageUploadField
              {...input}
              onBlur={input.onBlur}
              error={meta.touched && meta.error}
              category={ImageCategory.CandidateCompanyLogo}
              nounSingular="your square company logo"
              nounPlural="square company logos"
              fileCountMax={1}
              renderPreview={file => (
                <ImagePreview
                  src={file.path}
                  data-testid="company-logo-preview"
                />
              )}
            />
          </Spacing>
        )}
      </Field>
    </Spacing>
  );
}

function Stage({
  currentStage,
  form,
  experience,
}: {
  currentStage: FormStages | undefined;
  form: FormApi<EditedWorkExperienceItem, Partial<EditedWorkExperienceItem>>;
  experience: WorkExperienceItem | null;
}): React.ReactElement {
  switch (currentStage) {
    case FormStages.Overview:
      return <OverviewStage form={form} experience={experience} />;
    case FormStages.RoleDescription:
      return <RoleDescriptionStage />;
    case FormStages.CompanyDescription:
      return <CompanyDescriptionStage />;
    default:
      return <OverviewStage form={form} experience={experience} />;
  }
}

export function EditingWorkExperience({
  currentStage,
  form,
  experience,
}: {
  currentStage: FormStages | undefined;
  form: FormApi<EditedWorkExperienceItem, Partial<EditedWorkExperienceItem>>;
  experience: WorkExperienceItem | null;
}): React.ReactElement | null {
  return (
    <Spacing size={2}>
      <Breadcrumbs currentStage={currentStage} />
      <Stage currentStage={currentStage} form={form} experience={experience} />
    </Spacing>
  );
}
