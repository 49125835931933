import styled from "@xstyled/styled-components";

import { Text as DefaultText } from "../../Typography";

import { DefaultCheckmark } from "./Checkmark";

import { palette, modularScale, pxToRem } from "@otta/design-tokens";

const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  border-radius: 10px;
  width: ${modularScale(10)};
  height: ${pxToRem(35)};
  margin-right: 10;
  align-items: center;
  justify-content: flex-start;
`;

const Wrapper = styled.label`
  display: block;
  position: relative;
  padding: sm 0;
  cursor: pointer;
  user-select: none;
  color: black;
  input:checked ~ span:after {
    display: block;
  }
  span:after {
    left: ${pxToRem(6)};
    top: ${pxToRem(6)};
    width: ${pxToRem(5)};
    height: ${pxToRem(5)};
    border-radius: 50%;
    background-color: black;
    border: solid black;
  }
`;

const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

const Checkmark = styled(DefaultCheckmark)<{ checked: boolean }>`
  left: ${pxToRem(2)};
  height: ${pxToRem(22)};
  width: ${pxToRem(22)};
  border-radius: 50%;
  background-color: ${({ checked }) =>
    checked ? palette.brand.yellow : palette.grayscale.shade200};
  border: 1px solid
    ${({ checked }) =>
      checked ? palette.brand.yellow : palette.grayscale.shade200};
`;

const Text = styled(DefaultText)`
  margin-left: 35;
  margin-top: xxs;
`;

interface IRadioSelectionProps<V> {
  currency: V | null;
  onClick: (e: V) => void;
  disabled?: boolean;
  options: { value: V; label: string }[];
}

export function RadioCurrencySelection<V extends string>({
  currency,
  onClick,
  disabled,
  options,
}: IRadioSelectionProps<V>) {
  return (
    <CheckBoxContainer data-testid="radio-currency-selection-wrap">
      {options.map(({ value, label }) => (
        <CheckboxWrapper key={value}>
          <Wrapper>
            <Text>
              {value} ({label})
            </Text>
            <Input
              type="checkbox"
              checked={currency === value}
              onChange={() => onClick(value)}
              disabled={disabled}
              data-testid={`radio-currency-selection-${value}`}
            />
            <Checkmark checked={currency == value} />
          </Wrapper>
        </CheckboxWrapper>
      ))}
    </CheckBoxContainer>
  );
}
