import styled from "@xstyled/styled-components";

import { Text } from "../Typography";

import { DefaultCheckmark } from "./style/Checkmark";

import { palette, modularScale, pxToRem } from "@otta/design-tokens";

interface CheckMarkProps {
  disabled?: boolean;
}

const Label = styled.label<CheckMarkProps>`
  display: block;
  position: relative;
  user-select: none;
  padding-left: 40;
  margin: sm 0;
  cursor: ${({ disabled }) => (disabled ? "initial" : "pointer")};
  color: black;

  input ~ span {
    background-color: gray-200;
  }

  input:checked ~ span {
    background-color: ${({ disabled }) =>
      disabled ? palette.grayscale.shade200 : palette.brand.yellow};
  }

  input:checked ~ span:after {
    display: block;
  }

  span:after {
    left: ${modularScale(-4)};
    top: ${modularScale(-7)};
    width: ${modularScale(-8)};
    height: ${modularScale(-3)};
    border: solid
      ${({ disabled }) =>
        disabled ? `${palette.grayscale.shade200}` : `${palette.brand.black}`};
    border-width: 0 ${pxToRem(2)} ${pxToRem(2)} 0;
    transform: rotate(45deg);
  }
`;

const Input = styled.input`
  position: absolute;
  cursor: pointer;
  opacity: 0;
  height: 0;
  width: 0;
  outline: ${pxToRem(1)} solid ${palette.brand.black};
`;

const Checkmark = styled(DefaultCheckmark)<CheckMarkProps>`
  left: ${pxToRem(2)};
  height: ${modularScale(2)};
  width: ${modularScale(2)};
  border-radius: ${pxToRem(4)};
  background-color: ${({ disabled }) =>
    disabled ? palette.brand.black : palette.brand.grey};
`;

export interface ICheckboxProps extends CheckMarkProps {
  label: React.ReactNode;
  name?: string;
  value?: string;
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  className?: string;
}

/**
 * ```ts
 *
 * import { Checkbox } from '@otta/design'
 *
 * ```
 */

export const Checkbox = ({
  label,
  name,
  value,
  checked,
  onChange,
  disabled,
  className,
}: ICheckboxProps) => (
  <Label disabled={disabled} className={className}>
    {typeof label === "string" ? (
      <Text as="span" size={-1} align="left">
        {label}
      </Text>
    ) : (
      label
    )}
    <Input
      type="checkbox"
      name={name}
      disabled={disabled}
      value={value}
      checked={checked}
      onChange={
        onChange &&
        (e => {
          onChange(e.target.checked);
        })
      }
    />
    <Checkmark disabled={disabled} />
  </Label>
);
