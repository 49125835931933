import styled, { css, up } from "@xstyled/styled-components";

import chart from "./chart.png";

import { Text } from "@otta/design";
import { pxToRem, palette } from "@otta/design-tokens";
import { Gender } from "@otta/search/schema";
import { useWelcomeToTheJungle } from "@otta/search/hooks/useWelcomeToTheJungle";

const SalaryNudgeWrapper = styled.div`
  display: flex;
  position: relative;
  padding: 19 md;
  margin-top: 2rem;
  background-color: white;
  border-radius: ${pxToRem(7)};

  ${up(
    "tablet",
    css`
      width: 115%;
      margin-left: -5%;
    `
  )}

  &::before {
    content: " ";
    position: absolute;
    top: -0.75rem;
    left: 2rem;

    width: 0;
    height: 0;
    border-left: ${pxToRem(12)} solid transparent;
    border-right: ${pxToRem(12)} solid transparent;
    border-bottom: ${pxToRem(12)} solid ${palette.brand.white};
  }
`;

const SalaryNudgeContent = styled.div`
  text-align: left;
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
`;

const SalaryNudgeHeader = styled(Text)`
  font-size: ${pxToRem(13)};

  ${up(
    "tablet",
    css`
      font-size: ${pxToRem(18)};
    `
  )}
`;
const SalaryNudgeSubtitle = styled(Text)`
  font-size: ${pxToRem(12)};

  ${up(
    "tablet",
    css`
      font-size: ${pxToRem(16)};
    `
  )}
`;

export function GenderSalaryNudge({
  genders,
}: {
  genders: Gender[];
}): React.ReactElement | null {
  const enableGenderSalaryNudge = genders.some(
    (gender: Gender) => gender == Gender.Female
  );
  const companyName = useWelcomeToTheJungle();

  if (!enableGenderSalaryNudge) {
    return null;
  }

  return (
    <SalaryNudgeWrapper data-testid="gender-salary-nudge">
      <img width="68" height="64" src={chart} alt="pie chart" />
      <SalaryNudgeContent>
        <SalaryNudgeHeader bold>
          Women on {companyName} set their minimum
          <br /> expected salary 15% below men for all roles.
        </SalaryNudgeHeader>
        <SalaryNudgeSubtitle>
          So we show this banner as a nudge to increase your minimum.
        </SalaryNudgeSubtitle>
      </SalaryNudgeContent>
    </SalaryNudgeWrapper>
  );
}
