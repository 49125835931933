import Dropzone, { Accept, DropzoneRootProps } from "react-dropzone";
import { Field } from "react-final-form";
import styled from "@xstyled/styled-components";
import { useMemo } from "react";

import { Error } from "../../../components/FormData";
import { FileQuestion } from "../types";

import { Labelled } from "./Label";

import { palette } from "@otta/design-tokens";
import { Spacing } from "@otta/design";
import { AtsFileFormat } from "@otta/search/schema";
import { ExternalLink } from "@otta/search/components/ExternalLink";

function getColor(props: DropzoneRootProps): string {
  if (props.isDragAccept) {
    return palette.brand.green;
  }
  if (props.isDragReject) {
    return palette.extended.red.shade500;
  }
  if (props.isDragActive) {
    return palette.extended.yellow.shade100;
  }
  return palette.grayscale.shade200;
}

const DropzoneTitle = styled.div`
  margin-bottom: 10px;
  font-weight: 700;
`;

const DropzoneSubTitle = styled.div`
  font-style: italic;
  text-align: center;
`;

const DropzoneContainer = styled.div<DropzoneRootProps>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  border: 2px dashed ${palette.grayscale.shade200};
  border-color: ${props => getColor(props)};
  outline: none;
  transition: default;
  height: 200px;
  cursor: pointer;
  color: black;
  &:hover {
    background-color: rgba(0, 0, 0, 0.02);
  }
`;

const StyledError = styled(Error)`
  margin-top: 10px;
`;

const MAX_FILE_NUMBER = 1;

type FieldProps = {
  question: FileQuestion;
  sectionId: string;
};

function formatFileTypes(types: readonly AtsFileFormat[]): Accept {
  return types.reduce((accept, type) => {
    switch (type) {
      case AtsFileFormat.Doc:
        return { ...accept, "application/msword": [".doc"] };
      case AtsFileFormat.Docx:
        return {
          ...accept,
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            [".docx"],
        };
      case AtsFileFormat.Jpeg:
        return { ...accept, "image/jpeg": [".jpg", ".jpeg"] };
      case AtsFileFormat.Pdf:
        return { ...accept, "application/pdf": [".pdf"] };
      case AtsFileFormat.Png:
        return { ...accept, "image/png": [".png"] };
      case AtsFileFormat.Rtf:
        return { ...accept, "application/rtf": [".rtf"] };
      case AtsFileFormat.Txt:
        return { ...accept, "text/plain": [".txt"] };
      case AtsFileFormat.Xlsx:
        return {
          ...accept,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
            ".xlsx",
          ],
        };
      case AtsFileFormat.Zip:
        return { ...accept, "application/zip": [".zip"] };
    }
  }, {});
}

export function FileUploadField(props: FieldProps): React.ReactElement {
  const accept = useMemo(
    () => formatFileTypes(props.question.allowedFormats),
    [props]
  );
  const maxSizeMib = Math.floor(props.question.maximumBytes / 1024 / 1024);
  const extensions = Object.values(accept).flat().join(", ");
  const elementId = `${props.sectionId}_${props.question.id}`;

  return (
    <Labelled
      htmlFor={elementId}
      required={props.question.required}
      value={props.question.label}
    >
      {props.question.fileAnswer?.url && (
        <>
          <Field
            name={`s_${props.sectionId}.q_${props.question.localId}.a_0.fileValue.name`}
            component={"input"}
            type="hidden"
            initialValue={props.question.fileAnswer.name}
          />
          <Field
            name={`s_${props.sectionId}.q_${props.question.localId}.a_0.fileValue.url`}
            component={"input"}
            type="hidden"
            initialValue={props.question.fileAnswer.url}
          />
        </>
      )}

      <Field
        name={`s_${props.sectionId}.q_${props.question.localId}.a_0.uploadValue`}
        render={({ input: { onChange, value }, meta }) => {
          const handleDrop = (acceptedFiles: File[]) => {
            if (acceptedFiles.length === 1) {
              onChange(acceptedFiles[0]);
            }
          };
          return (
            <>
              <Spacing>
                {props.question.fileAnswer?.url && (
                  <ExternalLink
                    newTab
                    to={props.question.fileAnswer.url}
                    onClick={e => e.stopPropagation()}
                  >
                    {props.question.fileAnswer?.name}
                  </ExternalLink>
                )}
                <Dropzone
                  onDrop={handleDrop}
                  maxFiles={MAX_FILE_NUMBER}
                  maxSize={props.question.maximumBytes}
                  accept={accept}
                >
                  {({
                    getRootProps,
                    getInputProps,
                    isDragActive,
                    isDragAccept,
                    isDragReject,
                  }) => (
                    <DropzoneContainer
                      {...getRootProps({
                        isDragActive,
                        isDragAccept,
                        isDragReject,
                      })}
                    >
                      {value ? (
                        <>
                          <DropzoneTitle>{value.name}</DropzoneTitle>
                          <DropzoneSubTitle>
                            Click save to upload this file
                          </DropzoneSubTitle>
                        </>
                      ) : props.question.fileAnswer?.url ? (
                        <>
                          <DropzoneTitle>
                            Replace file: drag a file or click here to browse
                          </DropzoneTitle>
                          <DropzoneSubTitle>
                            {extensions} less than {maxSizeMib}mb
                          </DropzoneSubTitle>
                        </>
                      ) : (
                        <>
                          <DropzoneTitle>
                            Drag a file or click here to browse
                          </DropzoneTitle>
                          <DropzoneSubTitle>
                            {extensions} less than {maxSizeMib}mb
                          </DropzoneSubTitle>
                        </>
                      )}
                      <input
                        {...getInputProps()}
                        data-testid="file-upload-input"
                      />
                    </DropzoneContainer>
                  )}
                </Dropzone>
              </Spacing>
              {meta.error && meta.touched && (
                <StyledError>{meta.error}</StyledError>
              )}
            </>
          );
        }}
      />
    </Labelled>
  );
}
