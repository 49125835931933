import styled from "@xstyled/styled-components";
import * as RadixTooltip from "@radix-ui/react-tooltip";

import { modularScale } from "@otta/design-tokens";

export interface TooltipProps {
  children: React.ReactElement;
  content: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  placement?: "top" | "bottom" | "left" | "right";
}

const Content = styled(RadixTooltip.Content)`
  background-color: black;
  color: white;
  font-weight: 400;
  padding: lg;
  border-radius: 4px;
  font-size: ${modularScale(-1)};
  user-select: none;
  width: max-content;
  max-width: 340px;
  z-index: 50;
`;
const Arrow = styled(RadixTooltip.Arrow)`
  fill: black;
`;

export function Tooltip({
  content,
  children,
  style,
  className,
  placement,
}: TooltipProps): React.ReactElement {
  return (
    <RadixTooltip.Root>
      <RadixTooltip.Trigger asChild>{children}</RadixTooltip.Trigger>
      <RadixTooltip.Portal>
        <Content
          sideOffset={10}
          className={className}
          style={style}
          side={placement}
        >
          {content}
          <Arrow />
        </Content>
      </RadixTooltip.Portal>
    </RadixTooltip.Root>
  );
}
