import { ReactElement } from "react";
import styled, { css } from "@xstyled/styled-components";
import * as Dialog from "@radix-ui/react-dialog";

import { borderRadius, modularScale, pxToRem } from "@otta/design-tokens";

const Overlay = styled(Dialog.Overlay)`
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: grid;
  place-items: center;
  z-index: 100;
  padding: 19;
`;

const Content = styled(Dialog.Content)`
  position: relative;
  background-color: white;
  border-radius: ${pxToRem(borderRadius)};
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${pxToRem(560)};
  max-height: 80vh;
  z-index: 101;
  overflow: hidden;
`;

const InnerContent = styled.div<{ scrollable: boolean }>`
  flex: 1;
  ${({ scrollable }) =>
    scrollable &&
    css`
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
    `}
`;

const Delete = styled.button`
  all: unset;
  z-index: 2;
  cursor: pointer;
  position: absolute;
  top: ${modularScale(-2)};
  right: ${modularScale()};
  width: ${modularScale(1)};
  height: ${modularScale(1)};

  &::before,
  &::after {
    content: "";
    position: absolute;
    height: 21px;
    width: 2px;
    top: 0;
    left: 50%;
    background-color: gray-600;
    border-radius: 1px;
  }

  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
`;

const Header = styled.div`
  background-color: yellow-500;
  padding: lg 19 lg;
  border-radius: 12px 12px 0 0;
`;

export interface IModalProps {
  children: React.ReactNode;
  open: boolean;
  onOpenChange?: (value: boolean) => void;
  headerContent?: React.ReactNode;
  dismissable?: boolean;
  scrollable?: boolean;
  style?: React.CSSProperties;
  className?: string;
}

/**
 * This component functions as a pop-up window, and can have an optional header. This can be added with the `headerContent` prop.
 *
 * ```ts
 *
 * import { Modal } from '@otta/design'
 *
 * ```
 */
export function Modal({
  className,
  children,
  onOpenChange,
  open,
  headerContent,
  dismissable = true,
  scrollable = true,
  style,
}: IModalProps): ReactElement {
  return (
    <Dialog.Root
      open={open}
      onOpenChange={o => {
        if (o || dismissable) {
          onOpenChange?.(o);
        }
      }}
    >
      <Dialog.Portal>
        <Overlay />
        <Content
          style={style}
          className={className}
          data-testid="modal-content"
        >
          {dismissable && (
            <Dialog.Close aria-label="close" asChild>
              <Delete />
            </Dialog.Close>
          )}
          {headerContent && <Header>{headerContent}</Header>}
          <InnerContent scrollable={scrollable}>{children}</InnerContent>
        </Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
