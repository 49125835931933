import styled, { css, up } from "@xstyled/styled-components";
import { motion } from "framer-motion";
import { differenceInDays, differenceInHours, parseISO } from "date-fns";

import { Text, Tooltip } from "@otta/design";
import { Icon } from "@otta/icons";
import { pxToRem, palette } from "@otta/design-tokens";
import { Company, Job } from "@otta/search/schema";

const StatusTagsWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;

  ${up(
    "tablet",
    css`
      gap: 1rem;
      flex-direction: row-reverse;
      justify-content: flex-end;
    `
  )}

  @media (max-width: ${pxToRem(903)}) {
    flex-direction: column;
  }
`;
const JobStatusPill = styled(motion.div)<{ $live?: boolean }>`
  height: fit-content;
  width: fit-content;
  gap: 7;
  display: flex;
  border-radius: 0.5rem;
  background: ${({ $live }) =>
    $live ? palette.brand.green : palette.extended.red.shade200};
  padding: 5 sm;
`;

interface StatusTagsProps {
  job: Pick<Job.Fragment, "live" | "insertedAt" | "lastSeen" | "originalUrl">;
  company: Pick<Company.Fragment, "onlyOnOtta">;
}

export function StatusTags({
  job: { live, insertedAt, lastSeen, originalUrl },
  company: { onlyOnOtta },
}: StatusTagsProps): React.ReactElement {
  return (
    <StatusTagsWrapper>
      <ApplicationStatus
        live={live}
        insertedAt={insertedAt}
        lastSeen={lastSeen}
        originalUrl={originalUrl}
        onlyOnOtta={onlyOnOtta}
      />
    </StatusTagsWrapper>
  );
}

function ApplicationStatus({
  live,
  insertedAt,
  lastSeen,
  originalUrl,
  onlyOnOtta,
}: {
  live: boolean;
  insertedAt: string;
  lastSeen: string | null;
  originalUrl: string | null;
  onlyOnOtta: boolean;
}): React.ReactElement {
  const now = new Date();
  const postedAt = parseISO(insertedAt);
  const lastChecked = lastSeen ? parseISO(lastSeen) : now;
  const daysSincePosted = differenceInDays(now, postedAt);
  const hoursSinceChecked = differenceInHours(now, lastChecked);
  if (!live) {
    return (
      <JobStatusPill $live={false}>
        <Icon icon="circle-close" size={1} />
        <Text bold>Job no longer available</Text>
      </JobStatusPill>
    );
  }

  if (!originalUrl) {
    return (
      <JobStatusPill $live={live}>
        {daysSincePosted <= 1 ? (
          <Icon icon="new-to-otta" size={1} />
        ) : (
          <Icon icon="clock" size={1} />
        )}
        <Text bold>
          {daysSincePosted <= 1
            ? "Posted today"
            : daysSincePosted < 28
            ? "Be an early applicant"
            : "Open for applications"}
        </Text>
      </JobStatusPill>
    );
  }

  const content = onlyOnOtta
    ? `This job is checked every 24 hours.`
    : `We check jobs are live every few hours. This job was checked ${
        hoursSinceChecked < 1
          ? "less than 1 hour ago."
          : hoursSinceChecked === 1
          ? "1 hour ago."
          : `${hoursSinceChecked} hours ago.`
      }`;

  return (
    <Tooltip placement="bottom" content={content}>
      <JobStatusPill $live={live}>
        {daysSincePosted <= 1 ? (
          <Icon icon="new-to-otta" size={1} />
        ) : (
          <Icon icon="clock" size={1} />
        )}
        <Text bold>
          {daysSincePosted <= 1
            ? "Posted today"
            : daysSincePosted < 28
            ? "Be an early applicant"
            : "Open for applications"}
        </Text>
      </JobStatusPill>
    </Tooltip>
  );
}
