import styled from "@xstyled/styled-components";

import { Text } from "../../Typography";
import { Input } from "../Input";

import { MoneyInput, AccessibilityProps } from "./MoneyInput";

import { palette, pxToRem } from "@otta/design-tokens";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const Separator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: ${pxToRem(30)};
  color: gray-400;
`;

/**
 * This tremendous hack is styling the span when the component is visible
 * to highlight the entire field, not just the min/max, when focused
 * The alternative is useState misery
 */
const StyledInput = styled(Input)`
  background-color: transparent;
  -moz-appearance: textfield;
  pointer-events: auto;
  text-align: center;
  border: none;
  &:focus {
    border: none;
    margin-left: 1px;
  }
  &[aria-invalid="true"] ~ span:last-child {
    border: 2px solid ${palette.brand.red};
  }
  &:focus ~ span:last-child {
    border: ${pxToRem(2)} solid black;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const MinMoneyInputField = styled(StyledInput)`
  border-radius: 0;
`;

const MinMoneyInputFieldRounded = styled(MinMoneyInputField)`
  border-radius: ${pxToRem(5)} 0 0 ${pxToRem(5)};
`;

const MaxMoneyInputField = styled(StyledInput)`
  border-radius: 0 ${pxToRem(5)} ${pxToRem(5)} 0;
`;

const InputContainer = styled.div`
  position: relative;
  display: flex;
`;

/**
 * This tremendous hack is styling some spans when the component is visible
 * to highlight the entire field, not just the min/max, when focused
 * The alternative is useState misery
 */
const HackyBorderSpan = styled.span`
  border: 1px solid ${palette.grayscale.shade400};
  border-left: none;
  border-radius: 0 ${pxToRem(5)} ${pxToRem(5)} 0;
  pointer-events: none;
  position: absolute;
  display: block;
  inset: 0 0 0 0;
`;

interface IRangeFieldProps {
  values: { minAmount: number | null; maxAmount: number | null };
  prefix?: string;
  onChange?: (moneyRange: {
    minAmount: number | null;
    maxAmount: number | null;
  }) => void;
  onBlur?: () => void;
  placeholders?: { minAmount: string; maxAmount: string };
  disabled?: boolean;
  radio?: boolean;
  min?: AccessibilityProps;
  max?: AccessibilityProps;
}
export function RangeField({
  values,
  prefix,
  onChange,
  onBlur,
  placeholders,
  disabled,
  radio,
  min,
  max,
}: IRangeFieldProps) {
  return (
    <Wrapper>
      <InputContainer>
        <MoneyInput
          value={values.minAmount}
          prefix={prefix}
          customInput={radio ? MinMoneyInputFieldRounded : MinMoneyInputField}
          placeholder={placeholders?.minAmount}
          onBlur={onBlur}
          onChange={
            onChange && (minAmount => onChange({ ...values, minAmount }))
          }
          disabled={disabled}
          attrs={min}
        />
        <Separator>
          <Text>-</Text>
        </Separator>
        <MoneyInput
          value={values.maxAmount}
          prefix={prefix}
          customInput={MaxMoneyInputField}
          placeholder={placeholders?.maxAmount}
          onBlur={onBlur}
          onChange={
            onChange && (maxAmount => onChange({ ...values, maxAmount }))
          }
          disabled={disabled}
          attrs={max}
        />
        <HackyBorderSpan />
      </InputContainer>
    </Wrapper>
  );
}
