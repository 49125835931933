import styled, { css, th } from "@xstyled/styled-components";

import { modularScale } from "@otta/design-tokens";

type ButtonLevel = "primary" | "secondary" | "tertiary" | "destructive";
type SizeLevel = "regular" | "small";

export interface ButtonProps {
  level: ButtonLevel;
  size?: SizeLevel;
}

type CSS = ReturnType<typeof css>;

const sizing: Record<SizeLevel, CSS> = {
  regular: css`
    border-radius: 100;
    padding: md xxl;
    font-size: ${modularScale()};
    letter-spacing: 0.03em;
  `,
  small: css`
    border-radius: ${modularScale()};
    font-size: ${modularScale(-1)};
    padding: xs 6;
  `,
};

const colors: Record<ButtonLevel, CSS> = {
  primary: css`
    background-color: yellow-500;
    --border-color: ${th.color("yellow-500")};
    font-weight: 700;

    &:hover {
      background-color: yellow-200;
    }

    &:disabled {
      background-color: gray-100;
      --border-color: ${th.color("gray-200")};
      color: gray-400;
      cursor: not-allowed;
    }
  `,

  secondary: css`
    background-color: gray-50;
    --border-color: ${th.color("gray-200")};
    font-weight: 700;

    &:hover {
      background-color: gray-100;
    }

    &:disabled {
      background-color: gray-100;
      color: gray-400;
      cursor: not-allowed;
    }
  `,

  tertiary: css`
    background-color: white;
    font-weight: 400;
    --border-color: ${th.color("gray-200")};

    &:hover {
      --border-color: ${th.color("black")};
    }

    &:disabled {
      background-color: gray-100;
      color: gray-400;
      cursor: not-allowed;
    }
  `,
  destructive: css`
    background-color: gray-50;
    --border-color: ${th.color("red-600")};
    color: red-600;
    font-weight: 700;

    &:hover {
      background-color: gray-100;
    }

    &:disabled {
      background-color: gray-100;
      --border-color: ${th.color("gray-200")};
      color: gray-400;
      cursor: not-allowed;
    }
  `,
};
/**
 * ```ts
 *
 * import { Button } from '@otta/design'
 *
 * ```
 *
 * These are the buttons we use throughout all of our applications, and there are four levels to choose from:
 *
 * - <b>Primary (Yellow & Bold)</b>
 * - <b>Secondary (Gray & Bold)</b>
 * - <b>Tertiary (White)</b>
 * - <b>Destructive (Red & Gray)</b>
 *
 * You can see each type by using the controls below:
 */

export const Button = styled.button<ButtonProps>`
  color: black;
  cursor: pointer;
  font-family: inherit;
  -webkit-tap-highlight-color: transparent;
  border: 1px solid;
  outline: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-decoration: none;

  ${({ size = "regular" }) => sizing[size]}
  ${({ level }) => colors[level]}
  border-color: var(--border-color);

  transition: default;

  &:active {
    transform: scale(0.88);
  }

  &:focus-visible {
    box-shadow: 0 0 0 2px white, 0 0 0 4px var(--border-color);
  }
`;

export const Clickable = styled.button`
  all: unset;
  cursor: pointer;
`;
