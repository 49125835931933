import styled, { css } from "@xstyled/styled-components";
import { useParams } from "react-router-dom";
import { format, parseISO } from "date-fns";

import { Conversation } from "../InboxSidebar";

import { Card, Text } from "@otta/design";
import { pxToRem, modularScale, palette } from "@otta/design-tokens";
import { Link } from "@otta/search/components/Link";
import { CompanyLogoPlaceholder } from "@otta/search/components/CompanyLogoPlaceholder";
import { DoubleCheck } from "@otta/search/components/Icons/DoubleCheck";
import { pushAnalyticsEvent } from "@otta/analytics";

const StyledCard = styled(Card)<{ selected: boolean }>`
  background: ${palette.brand.white};

  &:hover {
    background: ${palette.grayscale.shade50};
  }

  ${({ selected }) =>
    selected &&
    css`
      background-color: yellow-100 !important;
    `}

  padding: 0;
  position: relative;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  column-gap: 12px;
  grid-template-areas:
    "logo title date"
    "logo message date";
`;

const StyledLink = styled(Link)`
  padding: md;
  display: flex;
  flex-direction: column;
`;

const JobTitle = styled(Text)`
  grid-area: title;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const LatestMessage = styled(Text)`
  display: inline-block;
  display: -webkit-box;
  grid-area: message;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
`;

const CompanyLogo = styled.img`
  grid-area: logo;
  width: 56px;
  height: 56px;
`;

const MessageDate = styled(Text)`
  grid-area: date;
  white-space: nowrap;
`;

const Pill = styled.div`
  background: ${palette.extended.red.shade400};
  border-radius: ${pxToRem(8)};
  padding: 4px 8px;
`;

const Dot = styled.div`
  width: ${modularScale(-2)};
  height: ${modularScale(-2)};
  background-color: red-600;
  border-radius: 50%;
  position: absolute;
  bottom: 12px;
  right: 12px;
`;

const DeliveredSymbol = styled(DoubleCheck)`
  height: ${modularScale(-1)};
  fill: gray-600;
  position: absolute;
  bottom: 12px;
  right: 12px;
`;

interface ICompanyImageProps {
  name: string;
  image: string | null;
  primaryColour: string | null;
}

export function CompanyImage({
  name,
  image,
  primaryColour,
}: ICompanyImageProps): React.ReactElement {
  if (image) {
    return <CompanyLogo src={image} alt={`${name} logo`} />;
  }

  return (
    <CompanyLogoPlaceholder backgroundColor={primaryColour}>
      {name.slice(0, 1)}
    </CompanyLogoPlaceholder>
  );
}

interface IInboxCardProps {
  id: string;
  to: string;
  accepted: boolean | null;
  sourced: boolean;
  candidateId: string;
  job: Conversation["job"];
  latestMessage: Conversation["latestMessage"];
}

export function InboxCard({
  id,
  to,
  accepted,
  sourced,
  candidateId,
  job,
  latestMessage,
}: IInboxCardProps): React.ReactElement {
  const sentByCandidate = latestMessage.sender.id === candidateId;

  const lastActionAt = latestMessage.insertedAt;

  const { conversationId: activeConversationId } = useParams<{
    conversationId?: string;
  }>();

  const textColor =
    sourced && accepted === false
      ? palette.grayscale.shade600
      : palette.brand.black;

  const timestamp = format(
    lastActionAt ? parseISO(lastActionAt) : new Date(),
    "dd MMM"
  );

  const { title, company } = job;

  return (
    <StyledCard selected={id === activeConversationId} data-testid="inbox-card">
      <StyledLink
        data-testid="message-link"
        to={to}
        underline={false}
        onClick={() =>
          pushAnalyticsEvent({
            eventName: "Candidate Clicked",
            name: "inbox-conversation",
            read: latestMessage.read,
            messageType: sourced && !accepted ? "message request" : "message",
            candidateResponded: sentByCandidate,
            lastMessage: sentByCandidate ? "candidate" : "recruiter",
          })
        }
      >
        <Row>
          <CompanyImage
            name={company.name}
            image={company.faviconPath}
            primaryColour={company.primaryColour}
          />
          <JobTitle data-cs-mask color={textColor}>
            <b>{company.name}</b> - {title}
          </JobTitle>
          <LatestMessage
            data-cs-mask
            size={-1}
            color={textColor}
            bold={!sentByCandidate && !latestMessage.read}
          >
            {latestMessage.content}
          </LatestMessage>
          <MessageDate size={-1} data-testid="message-date" color={textColor}>
            {timestamp}
          </MessageDate>
        </Row>
        {!sentByCandidate && sourced && !accepted && (
          <div style={{ display: "flex", marginTop: 12 }}>
            <Pill>
              <Text bold size={-1}>
                Needs response
              </Text>
            </Pill>
          </div>
        )}
        {!sentByCandidate && !latestMessage.read && (
          <Dot data-testid="unread-dot" />
        )}
        {sentByCandidate && latestMessage.read && (
          <DeliveredSymbol data-testid="double-check" />
        )}
      </StyledLink>
    </StyledCard>
  );
}
