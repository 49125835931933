import { motion, PanInfo } from "framer-motion";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import styled from "@xstyled/styled-components";

import { BrandCard } from "./CardItem";
import {
  blogTips,
  CardItem,
  createProfile,
  reviewProfile,
  reviewSavedJobs,
  takeAnotherLook,
} from "./CardTypes";

import { PageProgressIndicator, responsive, Spacing, Text } from "@otta/design";
import { modularScale } from "@otta/design-tokens";
import { pushAnalyticsEvent } from "@otta/analytics";
import { useQuery } from "@otta/search/apollo";
import {
  EmptyDashboardDocument,
  ProfileState,
  UserJobStatus,
} from "@otta/search/schema";
import { useWave } from "@otta/search/hooks/useWelcomeToTheJungle";

const StyledLink = styled(Link)`
  color: black;
`;

const HideWhenLoading = styled.div<{ $loading: boolean }>`
  opacity: ${p => (p.$loading ? "0" : "100")};
  transition: default;
`;

const Content = styled(Text)`
  ${responsive`
    font-size: ${{ mobile: modularScale(0), desktop: modularScale(2) }};
  `}
`;

const sendViewedAnalytics = (name: string) => {
  pushAnalyticsEvent({
    eventName: "Candidate Viewed Empty Dashboard Slide",
    name,
  });
};

const swipePower = (offset: number, velocity: number): number =>
  Math.abs(offset) * velocity;

const SWIPE_THRESHOLD = 30000;

const CARD_WIDTH = 308;

export function DashboardEmptyState(): React.ReactElement {
  const [position, setPosition] = useState(0);

  const { data, loading } = useQuery(EmptyDashboardDocument);

  const takeAnotherLookCount = data?.currentUser?.takeAnotherLook.length ?? 0;

  const savedJobs = useMemo(
    () =>
      data?.currentUser?.shortlistedJobs.filter(
        job => job.live && job.userJobStatusInfo.status === UserJobStatus.Saved
      ).length ?? 0,
    [data?.currentUser?.shortlistedJobs]
  );

  const hasProfile =
    data?.me?.__typename === "CurrentCandidate" ||
    data?.me?.__typename === "CurrentAdmin"
      ? data.me.profileStatus?.state !== ProfileState.NotCreated
      : false;

  useEffect(() => {
    if (!loading) {
      pushAnalyticsEvent({
        eventName: "Candidate Viewed Empty Dashboard",
        reviewSavedJobs: savedJobs > 0,
        profile: hasProfile ? "review" : "create",
        takeAnotherLook: takeAnotherLookCount > 0,
      });
    }
  }, [loading]);

  const wave = useWave();

  const cards = useMemo(
    () =>
      [
        takeAnotherLookCount > 0 && {
          component: takeAnotherLook(wave),
          jobCount: takeAnotherLookCount,
        },
        savedJobs > 0 && { component: reviewSavedJobs(wave) },
        { component: hasProfile ? reviewProfile(wave) : createProfile(wave) },
        { component: blogTips(wave) },
      ].filter((s): s is { component: CardItem; jobCount?: number } => !!s),
    [takeAnotherLookCount, savedJobs, hasProfile, wave]
  );

  const onDragEnd = useCallback(
    (_event: unknown, { offset, velocity }: PanInfo) => {
      const swipe = swipePower(offset.x, velocity.x);
      if (swipe < -SWIPE_THRESHOLD) {
        setPosition(position => {
          return position !== cards.length - 1 ? position + 1 : position;
        });
      } else if (swipe > SWIPE_THRESHOLD) {
        setPosition(position => {
          return position > 0 ? position - 1 : position;
        });
      }
    },
    [cards]
  );

  useEffect(() => {
    if (!loading) {
      sendViewedAnalytics(cards[position].component.title);
    }
  }, [cards, position, loading]);

  const carouselRef = useRef<HTMLDivElement>(null);

  const [showCarousel, setShowCarousel] = useState(false);
  const [carouselWidth, setCarouselWidth] = useState(0);

  useEffect(() => {
    const el = carouselRef.current;

    if (el) {
      const totalWidth = cards.length * CARD_WIDTH + 20 * (cards.length - 1);

      const o = new ResizeObserver(entries => {
        const entry = entries.find(e => e.target === el);
        if (!entry) {
          return;
        }

        setCarouselWidth(entry.contentRect.width);

        setShowCarousel(entry.contentRect.width < totalWidth);
      });

      o.observe(el);

      return () => {
        o.unobserve(el);
      };
    }
  }, [cards.length]);

  return (
    <HideWhenLoading $loading={loading}>
      <Spacing size={2}>
        <Content size={2}>
          You don&apos;t have any new matches, but we add jobs daily. For now,
          here&apos;s what you can do:
        </Content>
        <div ref={carouselRef}>
          {!showCarousel ? (
            <div
              style={{ display: "flex", flexDirection: "row", gap: 20 }}
              data-testid="empty-dashboard-carousel"
            >
              {cards.map(card => (
                <BrandCard
                  key={card.component.id}
                  item={card.component}
                  jobCount={card.jobCount}
                />
              ))}
            </div>
          ) : (
            <>
              <motion.div
                ref={carouselRef}
                data-testid="empty-dashboard-carousel"
                drag="x"
                dragSnapToOrigin
                dragConstraints={{ left: 0, right: 0 }}
                onDragEnd={onDragEnd}
                dragDirectionLock
                animate={{
                  left:
                    carouselWidth / 2 -
                    CARD_WIDTH / 2 -
                    position * (20 + CARD_WIDTH),
                }}
                style={{
                  display: "flex",
                  cursor: "grab",
                  width: "100%",
                  position: "relative",
                  alignContent: "flex-start",
                  marginBottom: modularScale(),
                  gap: 20,
                }}
              >
                {cards.map((card, index) => (
                  <motion.div
                    key={index}
                    animate={{ opacity: index === position ? 1 : 0.5 }}
                  >
                    <BrandCard item={card.component} jobCount={card.jobCount} />
                  </motion.div>
                ))}
              </motion.div>

              <PageProgressIndicator
                total={cards.length}
                current={position + 1}
                progress={position + 1}
              />
            </>
          )}
        </div>

        <Text size={0}>
          If you&apos;d like to explore different jobs, you can{" "}
          <StyledLink to="/preferences">
            expand your search preferences.
          </StyledLink>
        </Text>
      </Spacing>
    </HideWhenLoading>
  );
}
