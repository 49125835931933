import { useOutletContext } from "react-router-dom";
import { useUp } from "@xstyled/styled-components";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

import { JobCardContext } from "../JobHeader";

import { DesktopContent } from "./DesktopContent";
import { MobileContent } from "./MobileContent";

import { Loading } from "@otta/search/components/Loading";
import { pushAnalyticsEvent } from "@otta/analytics";
import {
  BrandAssetType,
  JobFragment,
  TeamMemberRole,
} from "@otta/search/schema";
import {
  useEBTracking,
  peopleBreakdownTracking,
} from "@otta/search/contexts/EBTracking";

export function JobContent(): React.ReactElement | null {
  const data = useOutletContext<JobCardContext | undefined>();
  const isTablet = useUp("tablet");

  const job = data?.job;
  const user = data?.user;

  if (!data || !job) {
    return <Loading />;
  }

  const { expandOtherJobs, otherJobsExpanded, toggleKeyboardShortcuts } = data;

  if (isTablet) {
    return (
      <DesktopContent
        job={job}
        user={user}
        expandOtherJobs={expandOtherJobs}
        otherJobsExpanded={otherJobsExpanded}
        toggleKeyboardShortcuts={toggleKeyboardShortcuts}
      />
    );
  }
  return (
    <MobileContent
      job={job}
      user={user}
      expandOtherJobs={expandOtherJobs}
      otherJobsExpanded={otherJobsExpanded}
    />
  );
}

export const useTrackEBContent = (job: JobFragment) => {
  const trackingData = useEBTracking();

  const [meetTheTeamSectionRef, isMeetTheTeamSectionInView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  const [peopleBreakdownRef, isPeopleBreakdownInView] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const isEBContentInView =
    isMeetTheTeamSectionInView || isPeopleBreakdownInView;

  useEffect(() => {
    if (!isEBContentInView) return;

    const teamManager = job.teamMembers?.find(
      teamMember => teamMember.role === TeamMemberRole.Manager
    );

    const videos = job.team?.brandAssetLinks
      .flatMap(link => link.companyBrandAsset)
      .filter(asset => asset.type === BrandAssetType.Vimeo)
      .map(asset => asset.category);

    pushAnalyticsEvent({
      eventName: "Candidate Viewed Job With EB Content",
      companyId: trackingData.companyId,
      jobId: trackingData.jobId,
      jobFunction: trackingData.jobFunction,
      jobSubFunction: trackingData.jobSubFunction,
      jobValueClassification: trackingData.jobValueClassification,
      minYearsExperienceRequired: trackingData.minYearsExperienceRequired,
      maxYearsExperienceRequired: trackingData.maxYearsExperienceRequired,
      applyViaOtta: trackingData.applyViaOtta,
      loginStatus: trackingData.loginStatus,
      videos,
      teamMission: !!job.team?.mission,
      teamSize: !!job.team?.size,
      yourTeamManager: !!teamManager,
      yourTeamReports: job.teamMembers?.some(
        teamMember => teamMember.role === TeamMemberRole.Report
      ),
      managerPrompts: !!teamManager?.jobCompanyTeamMemberPrompts?.length,
      peopleBreakdown: peopleBreakdownTracking(job.company?.ebStatistics),
    });
  }, [
    isEBContentInView,
    job.company?.ebStatistics,
    job.team?.brandAssetLinks,
    job.team?.mission,
    job.team?.size,
    job.teamMembers,
    trackingData.applyViaOtta,
    trackingData.companyId,
    trackingData.jobFunction,
    trackingData.jobId,
    trackingData.jobSubFunction,
    trackingData.jobValueClassification,
    trackingData.loginStatus,
    trackingData.maxYearsExperienceRequired,
    trackingData.minYearsExperienceRequired,
  ]);

  return {
    meetTheTeamSectionRef,
    peopleBreakdownRef,
  };
};
