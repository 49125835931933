import { NavLink, useLocation } from "react-router-dom";
import styled from "@xstyled/styled-components";
import { useMemo } from "react";

import { Text } from "@otta/design";
import { Icon } from "@otta/icons";
import { palette, pxToRem } from "@otta/design-tokens";
import { pushAnalyticsEvent } from "@otta/analytics";

const onTabClicked = (tab: "job-tab" | "company-profile-tab") => {
  pushAnalyticsEvent({
    eventName: "Candidate Clicked",
    name: tab,
    pathname: location.pathname,
  });
};

const CompanyNavLinks = styled.div`
  background-color: white;
  justify-content: center;
  display: flex;
  width: 100%;
  gap: 1.5rem;
`;

const BigIcon = styled(Text).attrs(p => ({ ...p, size: 4 }))`
  display: flex;
`;

const TabLink = styled(NavLink)`
  color: black;
  text-decoration: none;
  align-items: center;
  position: relative;
  padding: 0.75rem 0;
  font-weight: 400;
  display: flex;
  gap: 0.25rem;

  &.active {
    font-weight: 700;
  }

  &.active::after {
    content: "";
    inset: 0 0 0 0;
    position: absolute;
    border-bottom: ${pxToRem(4)} solid ${palette.brand.yellow};
  }
`;

export function NavLinks() {
  const { pathname, search } = useLocation();

  // react router is bad
  const path = useMemo(() => pathname.replace(/\/(company)?$/, ""), [pathname]);

  return (
    <CompanyNavLinks data-testid="company-nav">
      <TabLink
        data-no-scroll="true"
        to={`${path}${search}`}
        onClick={() => onTabClicked("job-tab")}
        end
      >
        <BigIcon>
          <Icon icon="industry" />
        </BigIcon>
        Job
      </TabLink>
      <TabLink
        data-no-scroll="true"
        to={`${path}/company${search}`}
        onClick={() => onTabClicked("company-profile-tab")}
      >
        <BigIcon>
          <Icon icon="company" />
        </BigIcon>
        Company
      </TabLink>
    </CompanyNavLinks>
  );
}
