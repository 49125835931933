import styled from "@xstyled/styled-components";
import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import { modularScale } from "@otta/design-tokens";
import { Heading, Text, VerticalSpacing } from "@otta/design";
import { pushAnalyticsEvent } from "@otta/analytics";
import { SignupForm } from "@otta/search/pages/Signup/SignupForm";
import { AutoPopulatePreferencesDocument } from "@otta/search/schema";

const SignupWrapper = styled(VerticalSpacing)`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: beige-100;
  padding: xl lg;
  flex: 1;
  > * {
    max-width: ${modularScale(19)};
  }
`;

const StyledHeading = styled(Heading)`
  padding: 28 lg;
  background-color: beige-200;
`;

export const ApplySignUp = (): React.ReactElement => {
  const { jobExternalId } = useParams<"jobExternalId">();

  const [autoPopulatePreferences] = useMutation(
    AutoPopulatePreferencesDocument
  );

  const onSignupComplete = useCallback(() => {
    if (jobExternalId) {
      autoPopulatePreferences({ variables: { jobExternalId } });
    }
    pushAnalyticsEvent({
      eventName: "Candidate Signed Up via Public Job Apply",
    });
  }, [autoPopulatePreferences, jobExternalId]);

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
        <link rel="canonical" href={`https://app.otta.com/apply/sign-up`} />
      </Helmet>
      <StyledHeading size={2} align="center">
        Create an account to save and track your application
      </StyledHeading>
      <SignupWrapper size={2}>
        <Text align="center">
          You can save and resume your application later, and receive secure
          messages from hiring managers
        </Text>
        <SignupForm
          hideLoginLink
          showEmailSlider
          onSignupCallback={onSignupComplete}
        />
      </SignupWrapper>
    </>
  );
};
