import styled, { css, down, up } from "@xstyled/styled-components";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useInView } from "react-intersection-observer";

import {
  ShortlistCardActionButtons,
  IShortlistCardProps,
} from "../ShortlistCard";
import { SalaryTechExperienceLocation } from "../../Jobs/JobCard/responsiveComponents/SalaryTechExperienceLocation";

import { Benefits } from "./SpotlightCards/Benefits";
import { CarouselCard } from "./SpotlightCards/components";
import { TeamMission } from "./SpotlightCards/TeamMission";
import { MeetTheTeam } from "./SpotlightCards/MeetTheTeam";
import { Headquarters } from "./SpotlightCards/Headquarters";
import { SimpleOrgChart } from "./SpotlightCards/SimpleOrgChart";
import { HiringManager } from "./SpotlightCards/HiringManager";

import { Card } from "@otta/design";
import { palette, pxToRem } from "@otta/design-tokens";
import {
  BrandAssetGroup,
  BrandAssetType,
  CompanyBrandAsset,
  DashboardCompanyFragment,
  DashboardJobFragment,
  Job,
  ShortlistJobDocument,
  TeamMemberRole,
  UserJobPreferences,
  UserJobPreferencesDocument,
} from "@otta/search/schema";
import { useQuery } from "@otta/search/apollo";
import { Loading } from "@otta/shared-components";
import { useUserLocation } from "@otta/search/hooks/useUserLocation";
import { countryToCurrency } from "@otta/search/utils/currencies";
import { JobInfo } from "@otta/search/components/JobPreviewCard";
import { Carousel } from "@otta/search/components/Carousel";
import { useEBTracking } from "@otta/search/contexts/EBTracking";
import { pushAnalyticsEvent } from "@otta/analytics";

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const SpotlightCarouselContainer = styled.div`
  flex-grow: 1;
  align-items: center;
  background: ${palette.grayscale.shade50};
  overflow: hidden;
  padding: lg;

  ${up(
    "desktop",
    css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-radius: 0;

      .carousel-card-hidden {
        .spotlight-content-tag {
          opacity: 0;
          pointer-events: none;
        }
      }
    `
  )}
`;

const StaticContainer = styled(Column)`
  flex-shrink: 0;

  ${up(
    "tablet",
    css`
      justify-content: space-between;
      padding: xl;
      width: ${pxToRem(340)};
    `
  )};
`;

const StaticTopSection = styled(Column)`
  ${down(
    "tablet",
    css`
      background: ${palette.brand.white};
      padding: lg;
    `
  )}
`;

const MobileContainer = styled(Column)`
  border-radius: ${pxToRem(16)};
  overflow: hidden;
`;

const Container = styled.div`
  ${up(
    "desktop",
    css`
      height: ${pxToRem(392)};
    `
  )}
`;

export type CardTracking = {
  name: string;
  videoId?: string | null;
  videoCategory?: string | null;
};

function StaticSection({
  job,
  section,
  onChange,
  user,
  isMobile = false,
  isBatchEnd,
  cardsForTracking,
  currentCard,
}: Pick<IShortlistCardProps, "section" | "onChange" | "isBatchEnd"> & {
  user?: UserJobPreferences.CurrentUser;
  job: Job.Fragment;
  isMobile?: boolean;
  cardsForTracking: CardTracking[];
  currentCard: number;
}) {
  return (
    <StaticContainer>
      <StaticTopSection>
        <JobInfo
          title={job.title}
          subtitle={job.subtitle}
          company={job.company}
        />
        {isMobile ? (
          <Row style={{ gap: pxToRem(8) }}>
            <ShortlistCardActionButtons
              job={job}
              section={section}
              onChange={onChange}
              isBatchEnd={isBatchEnd}
              cardsForTracking={cardsForTracking}
              currentCard={currentCard}
            />
          </Row>
        ) : (
          <SalaryTechExperienceLocation job={job} user={user} />
        )}
      </StaticTopSection>
      {!isMobile && (
        <Row style={{ gap: pxToRem(8) }}>
          <ShortlistCardActionButtons
            job={job}
            section={section}
            onChange={onChange}
            isBatchEnd={isBatchEnd}
            cardsForTracking={cardsForTracking}
            currentCard={currentCard}
          />
        </Row>
      )}
    </StaticContainer>
  );
}

function SpotlightCarousel({
  cards,
  handleChange,
}: {
  cards: React.ReactElement[];
  handleChange: (currentCard: number) => void;
}) {
  return (
    <SpotlightCarouselContainer>
      <Carousel cards={cards} onChange={handleChange} />
    </SpotlightCarouselContainer>
  );
}

export function SpotlightShortlistCard({
  jobId,
  company,
  section,
  onChange,
  isMobile = true,
  isBatchEnd,
}: Omit<IShortlistCardProps, "job"> & {
  jobId: string;
  company: DashboardCompanyFragment;
  isMobile?: boolean;
}) {
  const { data: userData, loading: userLoading } = useQuery(
    UserJobPreferencesDocument
  );
  const trackingData = useEBTracking();
  const [wrapperRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  const [currentCard, setCurrentCard] = useState<number>(1);

  const { country: userLocation } = useUserLocation();
  const currency = countryToCurrency(userLocation);

  const { data, loading } = useQuery(ShortlistJobDocument, {
    variables: { externalId: jobId as string, currency },
  });

  const { cards, cardsForTracking } = useMemo(() => {
    if (!data?.publicJob)
      return {
        cards: [],
        cardsForTracking: [],
      };

    const benefitLinks = company.brandAssetGroups.find(
      ({ group }) => group.name === BrandAssetGroup.CompanySpotlightBenefit
    )?.links;

    const meetTheTeamLinks = data.publicJob.team?.brandAssetLinks;

    const officeAssets = company.brandAssetGroups.filter(
      ({ group }) => group.name === BrandAssetGroup.CompanySpotlightOffice
    );

    const manager = data?.publicJob?.teamMembers?.find(
      ({ role }) => role === TeamMemberRole.Manager
    );

    const getVideoTrackingContent = (
      companyBrandAsset: Pick<CompanyBrandAsset, "type" | "id" | "category">
    ) => {
      if (companyBrandAsset?.type !== BrandAssetType.Vimeo) return {};
      return {
        videoId: companyBrandAsset.id,
        videoCategory: companyBrandAsset.category,
      };
    };

    const cards = [];
    const cardsForTracking: CardTracking[] = [];

    if (isMobile) {
      cards.push(
        <CarouselCard>
          <SalaryTechExperienceLocation
            job={data.publicJob}
            user={userData?.currentUser ?? undefined}
          />
        </CarouselCard>
      );
      cardsForTracking.push({
        name: "salary tech experience location",
      });
    }

    if (meetTheTeamLinks && meetTheTeamLinks?.length > 0) {
      cards.push(<MeetTheTeam asset={meetTheTeamLinks[0]} />);
      cardsForTracking.push({
        name: "meet the team",
        ...getVideoTrackingContent(meetTheTeamLinks[0].companyBrandAsset),
      });
    }

    if (manager && manager.jobCompanyTeamMemberPrompts?.length > 0) {
      cards.push(<HiringManager manager={manager} />);
      cardsForTracking.push({
        name: "hiring manager",
        ...getVideoTrackingContent(
          manager.member.promptLinks[0]?.companyBrandAsset
        ),
      });
    }

    if (data?.publicJob && manager) {
      cards.push(
        <SimpleOrgChart
          members={data.publicJob.teamMembers}
          size={data.publicJob.team?.size ?? undefined}
        />
      );
      cardsForTracking.push({
        name: "simple org chart",
      });
    }

    if (data?.publicJob?.team?.mission) {
      cards.push(
        <TeamMission
          name={data.publicJob.team.name}
          mission={data.publicJob.team.mission}
        />
      );
      cardsForTracking.push({
        name: "team mission",
      });
    }

    if (benefitLinks && benefitLinks?.length > 0) {
      cards.push(<Benefits asset={benefitLinks[0]} />);
      cardsForTracking.push({
        name: "benefits",
        ...getVideoTrackingContent(benefitLinks[0].companyBrandAsset),
      });
    }

    if (
      company?.offices &&
      company.offices?.length > 0 &&
      officeAssets?.length > 0
    ) {
      cards.push(
        <Headquarters assets={officeAssets} office={company.offices?.at(0)} />
      );
      cardsForTracking.push({
        name: "headquarters",
        ...getVideoTrackingContent(officeAssets[0].links[0].companyBrandAsset),
      });
    }

    return {
      cards,
      cardsForTracking,
    };
  }, [
    company.brandAssetGroups,
    company.offices,
    data?.publicJob,
    isMobile,
    userData?.currentUser,
  ]);

  useEffect(() => {
    if (cardsForTracking.length === 0 || !inView) return;

    pushAnalyticsEvent({
      eventName: "Candidate Viewed Enhanced Saved Job",
      page: isBatchEnd ? "batch End" : "dashboard",
      companyId: trackingData.companyId,
      jobId: trackingData.jobId,
      jobFunction: trackingData.jobFunction,
      jobSubFunction: trackingData.jobSubFunction,
      jobValueClassification: trackingData.jobValueClassification,
      minYearsExperienceRequired: trackingData.minYearsExperienceRequired,
      maxYearsExperienceRequired: trackingData.maxYearsExperienceRequired,
      carouselCardsCount: cardsForTracking.length,
      currentCardContent: cardsForTracking[0].name,
      currentCardVideoCategory: cardsForTracking[0].videoCategory,
      currentCardVideoId: cardsForTracking[0].videoId,
    });
  }, [cardsForTracking, isBatchEnd, trackingData, inView]);

  const handleChange = useCallback(
    (newCardIndex: number) => {
      setCurrentCard(newCardIndex);

      pushAnalyticsEvent({
        eventName: "Candidate Clicked Enhanced Saved Job",
        page: isBatchEnd ? "batch-end" : "dashboard",
        companyId: trackingData.companyId,
        jobId: trackingData.jobId,
        carouselCardsCount: cardsForTracking.length,
        isEndCard: newCardIndex === cardsForTracking.length - 1,
        currentCardContent: cardsForTracking[newCardIndex].name,
        currentCardVideoCategory: cardsForTracking[0].videoCategory,
        currentCardVideoId: cardsForTracking[0].videoId,
      });
    },
    [cardsForTracking, isBatchEnd, trackingData]
  );

  if (loading || userLoading) {
    return <Loading />;
  }

  if (!data?.publicJob) {
    return null;
  }

  return (
    <Container ref={wrapperRef} data-testid="spotlight-preview-card">
      {isMobile ? (
        <MobileContainer>
          <StaticSection
            key="static-section-asset-card"
            job={data.publicJob}
            section={section}
            onChange={onChange}
            user={userData?.currentUser ?? undefined}
            isMobile
            isBatchEnd={isBatchEnd}
            cardsForTracking={cardsForTracking}
            currentCard={currentCard}
          />
          <SpotlightCarousel cards={cards} handleChange={handleChange} />
        </MobileContainer>
      ) : (
        <Card
          style={{
            height: "100%",
            position: "relative",
            overflow: "hidden",
            padding: 0,
          }}
        >
          <Row style={{ height: "100%" }}>
            <StaticSection
              job={data.publicJob}
              section={section}
              onChange={onChange}
              user={userData?.currentUser ?? undefined}
              isBatchEnd={isBatchEnd}
              cardsForTracking={cardsForTracking}
              currentCard={currentCard}
            />

            <SpotlightCarousel cards={cards} handleChange={handleChange} />
          </Row>
        </Card>
      )}
    </Container>
  );
}

export function isSpotlightJob(job: DashboardJobFragment) {
  if (!job.live) return false;

  return (
    job.company.brandAssetGroups.length > 0 ||
    job.team?.mission ||
    job.teamMembers.some(
      member => member.jobCompanyTeamMemberPrompts?.length > 0
    )
  );
}
