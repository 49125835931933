import styled, { css, up } from "@xstyled/styled-components";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { Button, Text } from "@otta/design";
import { palette } from "@otta/design-tokens";
import { UploadCvDocument } from "@otta/search/schema";
import { pushAnalyticsEvent } from "@otta/analytics";

const Grid = styled.div`
  display: grid;
  gap: 1rem;

  ${up(
    "tablet",
    css`
      grid-template-columns: 1fr 1fr;
    `
  )}
`;

const Box = styled.div`
  background-color: gray-50;
  padding: 1.5rem;
  border-radius: 0.75rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: space-between;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export function CVUpload({
  onEdit,
  redirect,
}: {
  onEdit: () => void;
  redirect?: string | null;
}) {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [upload, { loading }] = useMutation(UploadCvDocument, {
    onError() {
      toast.error("Something went wrong uploading your CV. Try again");
    },
  });

  const dropzone = useDropzone({
    onDrop([file]) {
      if (file) {
        pushAnalyticsEvent({ eventName: "Candidate uploaded CV" });
        upload({
          variables: { file },
          onCompleted() {
            navigate(
              `/profile/import${redirect ? `?redirect=${redirect}` : ""}`
            );
          },
        });
      }
    },
    maxFiles: 1,
    accept: { "application/pdf": [] },
    disabled: loading,
  });

  return (
    <Grid>
      <Box
        {...dropzone.getRootProps()}
        style={{ border: `1px dashed ${palette.grayscale.shade400}` }}
      >
        <input {...dropzone.getInputProps()} data-testid="cv-input" />
        <TextContainer>
          <Text bold>{t("Import from your CV")}</Text>
          <Text>Upload a PDF to pre-fill your profile (max 10MB)</Text>
        </TextContainer>
        <Button disabled={loading} level="primary" style={{ width: "100%" }}>
          {loading ? "Uploading..." : t("Upload CV")}
        </Button>
      </Box>
      <Box>
        <TextContainer>
          <Text bold>Start from scratch</Text>
          <Text>
            Don&apos;t have {t("a CV")} or would rather not use it? No problem.
            We can help you create one.
          </Text>
        </TextContainer>
        <Button
          disabled={loading}
          level="secondary"
          data-analytics-id="start-from-scratch"
          onClick={onEdit}
          style={{ width: "100%" }}
        >
          Start from scratch
        </Button>
      </Box>
    </Grid>
  );
}
