import { Experiment } from "../constants/experiments";

import { useExperiment } from "./unleash";

export function useWelcomeToTheJungle(): string {
  return useWave() === "wave2" ? "Welcome to the Jungle" : "Otta";
}

export function useWave(): "wave1" | "wave2" | "control" {
  return (useExperiment(Experiment.OttaWttjRebranding)?.variant ??
    "control") as "wave1" | "wave2" | "control";
}
