import styled from "@xstyled/styled-components";

import { useHasNewLogo } from "../Logo";

import { Footer } from "./Footer";

import {
  Spacing,
  Heading,
  Middle,
  Paragraph,
  Logo as OttaLogo,
  WttjLogo,
  ResponsiveImage,
} from "@otta/design";
import { pxToRem, pxToEm, palette, boxShadow } from "@otta/design-tokens";
import { useWelcomeToTheJungle } from "@otta/search/hooks/useWelcomeToTheJungle";

const Page = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Nav = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: md;
  background: ${palette.brand.white};
  box-shadow: ${boxShadow};
  height: ${pxToRem(50)};
  z-index: 1;
`;

const Hero = styled.div`
  background: ${palette.brand.yellow};
  position: relative;
  overflow: hidden;
  padding: lg;
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: lg;
`;

const HeroImage = styled(ResponsiveImage)`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  min-height: 100vh;
  min-width: 110%;
  max-width: unset;

  @media (min-width: 1760px) {
    min-width: unset;
    max-width: ${pxToRem(1600)};
  }
`;

const Section = styled(Middle)`
  z-index: 1;
  position: relative;
`;

const StyledOttaLogo = styled(OttaLogo)`
  height: 100%;
`;

const StyledWttjLogo = styled(WttjLogo)`
  height: 100%;
`;

export function Maintenance(): React.ReactElement {
  const hasNewLogo = useHasNewLogo();
  const companyName = useWelcomeToTheJungle();

  return (
    <Page>
      <Nav>
        <a href="https://app.otta.com/">
          {hasNewLogo ? <StyledWttjLogo /> : <StyledOttaLogo />}
        </a>
      </Nav>
      <Hero>
        <Section maxWidth={1000}>
          <Heading size={8} as="h1">
            One sec, we&apos;ll be right back
          </Heading>
        </Section>
        <Section maxWidth={700}>
          <Spacing size={5}>
            <Paragraph size={2}>
              Sorry, {companyName} is offline. We&apos;re either upgrading the
              site or something&apos;s gone wrong. We&apos;re working hard to
              get back online quickly.
            </Paragraph>
          </Spacing>
        </Section>

        <HeroImage
          src={width =>
            `https://static.otta.com/hero-images/colourway-2/invest_in_yourself_${width}.png`
          }
          sources={{
            "image/webp": width =>
              `https://static.otta.com/hero-images/colourway-2/invest_in_yourself_${width}.webp`,
          }}
          widths={[640, 768, 1024, 1366, 1600, 1920]}
          sizes={["110vw", `(min-width: ${pxToEm(1760)}) ${pxToRem(1600)}`]}
        />
      </Hero>
      <Footer />
    </Page>
  );
}
