import { useNavigate, useSearchParams } from "react-router-dom";
import styled, { css, down, up, useUp } from "@xstyled/styled-components";
import { motion, AnimatePresence, PanInfo } from "framer-motion";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-use";

import { JobCard, JobCardType } from "../JobCard";
import { PageProgressIndicator as PageProgressIndicatorV2 } from "../JobCard/components/PageProgressIndicator";
import { useJobRecommendations } from "../JobRecommendationProvider";

import { BatchEnd, BatchEndTop } from "./BatchEnd";
import { DragGuideOverlay } from "./DragGuideOverlay";
import {
  batchName,
  BatchOptions,
  getThemeId,
  analyticsFields,
} from "./BatchOptions";
import { ThemeBatchHeading } from "./ThemeBatchHeading";
import { JobCardNav } from "./components/JobCardNav";

import { palette } from "@otta/design-tokens";
import { Middle } from "@otta/design";
import { useQuery } from "@otta/search/apollo";
import { pushAnalyticsEvent } from "@otta/analytics";
import {
  JobDeckDocument,
  ThemeId,
  LastUnconfirmedJobApplicationDocument,
  UserJobPreferencesDocument,
} from "@otta/search/schema";
import { ApplyModal } from "@otta/search/components/ApplyModal";
import { useUserLocation } from "@otta/search/hooks/useUserLocation";
import { countryToCurrency } from "@otta/search/utils/currencies";
import { DidYouApplyModal } from "@otta/search/components/DidYouApplyModal";
import {
  getSalaryStatus,
  jobValueClassification,
} from "@otta/search/utils/analytics/jobProperties";

const PageProgressContainer = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: lg 0;
  z-index: 0;

  ${up(
    "tablet",
    css`
      background: ${palette.brand.yellow};
    `
  )}
`;

const PageContainer = styled.div<{ batchEnd: boolean }>`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;

  ${({ batchEnd }) =>
    !batchEnd &&
    css`
      ${down(
        "tablet",
        css`
          padding-bottom: 69;
        `
      )}
      ${up(
        "tablet",
        css`
          padding: 0;
          padding-bottom: 64px;
        `
      )}
    `}
`;
function MaybeMiddle({
  children,
  batchEnd,
  version2,
}: React.PropsWithChildren<{ batchEnd: boolean; version2: boolean }>) {
  return batchEnd || version2 ? (
    <div style={{ width: "100%" }}>{children}</div>
  ) : (
    <Middle style={{ width: "100%" }} textAlign="left" maxWidth={1024}>
      {children}
    </Middle>
  );
}

const transition = {
  x: { type: "spring", stiffness: 300, damping: 30 },
};

const SWIPE_THRESHOLD = 30000;

const swipePower = (offset: number, velocity: number): number => {
  return Math.abs(offset) * velocity;
};

interface JobDeckProps {
  jobId?: string;
  jobs: string[];
  token?: string;
  progress: number;
  params: BatchOptions;
}

export function JobDeck({
  jobId,
  jobs,
  token,
  progress,
  params,
}: JobDeckProps): React.ReactElement {
  const { save, skip } = useJobRecommendations();
  const location = useLocation();
  const [qs] = useSearchParams();
  const isTablet = useUp("tablet");

  const isBatchEnd = jobId === "batch-end";

  const index = useMemo(() => {
    if (isBatchEnd) {
      return jobs.length;
    }
    return jobs.findIndex(job => job === jobId);
  }, [isBatchEnd, jobId, jobs]);

  const [direction, setDirection] = useState(1);
  const [showApplyModal, setShowApplyModal] = useState(false);
  const [showDidYouApplyModal, setShowDidYouApplyModal] = useState(false);
  const [canUseKeyboardShortcuts, setCanUseKeyboardShortcuts] = useState(true);
  const [hideKeyboardShortcuts, setHideKeyboardShortcuts] = useState(true);

  const loginStatus = token ? "email-token" : "logged-in";

  useEffect(() => {
    setHideKeyboardShortcuts(
      localStorage.getItem("hide_job_deck_keyboard_hints") === "true" ||
        !isTablet
    );
  }, [isTablet]);

  useEffect(() => {
    if (jobId && jobs.indexOf(jobId) === 0) {
      pushAnalyticsEvent({
        eventName: "Candidate Viewed Job Deck",
        deckLength: jobs.length,
        loginStatus,
        ...analyticsFields(params),
      });
    }
  }, [jobs, loginStatus, params, jobId]);

  useEffect(() => {
    if (isBatchEnd) {
      localStorage.setItem("hide_job_deck_keyboard_hints", "true");
    }
  }, [isBatchEnd]);

  const navigate = useNavigate();

  const prevJob = jobs[index - 1];
  const currJob = jobs[index];
  const nextJob = jobs[index + 1];

  const showBatchEndTop =
    !(params.type === "theme" && params.theme === ThemeId.TakeAnotherLook) &&
    progress >= jobs.length &&
    index < jobs.length;

  const { country: userLocation } = useUserLocation();

  const buildJobDeckQueryVariables = (jobId: string) => {
    return {
      skip: !jobId || jobId === "batch-end",
      context: { emailToken: token },
      variables: {
        externalId: jobId,
        currency: countryToCurrency(userLocation),
        prefixImage: false,
      },
    };
  };

  const { data: jobData, loading: currJobLoading } = useQuery(
    JobDeckDocument,
    buildJobDeckQueryVariables(currJob)
  );

  useQuery(JobDeckDocument, buildJobDeckQueryVariables(prevJob));

  useQuery(JobDeckDocument, buildJobDeckQueryVariables(nextJob));

  const { data: userData, loading: userLoading } = useQuery(
    UserJobPreferencesDocument
  );

  const { data: lastUnconfirmedJobApplication } = useQuery(
    LastUnconfirmedJobApplicationDocument
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [jobId]);

  const handleMove = useCallback(
    (newDirection: number) => {
      setDirection(newDirection);

      if (newDirection === -1 && prevJob) {
        navigate(
          { pathname: `../${prevJob}`, search: location.search },
          { state: { token } }
        );
      } else if (newDirection === 1 && nextJob) {
        navigate(
          { pathname: `../${nextJob}`, search: location.search },
          { state: { token } }
        );
      } else if (newDirection === 1) {
        navigate(
          { pathname: "../batch-end", search: location.search },
          { state: { token } }
        );
      }
    },
    [location.search, navigate, nextJob, prevJob, token]
  );

  const handleNext = useCallback(() => {
    if (
      jobData?.publicJob &&
      (!jobData?.publicJob?.latestReaction ||
        (params.type === "theme" &&
          params.theme === ThemeId.TakeAnotherLook &&
          jobData.publicJob.latestReaction?.direction === false))
    ) {
      skip(jobData.publicJob);
    }
    handleMove(1);
  }, [params, jobData, handleMove, skip]);

  const themeName = batchName(params);
  const themeId = getThemeId(params);

  const handlePrevious = useCallback(() => {
    if (prevJob) {
      handleMove(-1);
    }
  }, [handleMove, prevJob]);

  const handleSave = useCallback(() => {
    if (!jobData?.publicJob) {
      return;
    }
    if (jobData.publicJob.latestReaction?.direction) {
      skip(jobData.publicJob);
    } else {
      save(jobData.publicJob);
    }
  }, [jobData, save, skip]);

  const handleApply = useCallback(() => {
    const analyticsEventData = {
      jobValueClassification: jobValueClassification(
        jobData?.publicJob?.function?.id
      ),
      jobTitle: jobData?.publicJob?.title,
      companyName: jobData?.publicJob?.company.name,
      jobId: jobData?.publicJob?.id,
      function: jobData?.publicJob?.function?.value,
      subFunction: jobData?.publicJob?.subFunction?.value,
      minYearsExperienceRequired:
        jobData?.publicJob?.minYearsExperienceRequired,
      maxYearsExperience: jobData?.publicJob?.maxYearsExperienceRequired,
      applyViaOtta: jobData?.publicJob?.acceptsInternalApplications,
      technologiesUsed: (jobData?.publicJob?.technologiesUsed ?? [])
        .map(({ value }) => value)
        .join(", "),
      salaryStatus: getSalaryStatus(jobData?.publicJob),
    };

    pushAnalyticsEvent({
      eventName: "Candidate Clicked",
      name: "job-card-apply-button",
      pathname: location.pathname,
      internal: true,
      loginStatus,
      themeId,
      ...analyticsEventData,
    });

    setShowApplyModal(true);
  }, [jobData, location.pathname, loginStatus, themeId]);

  useEffect(() => {
    const keyupListener = (event: KeyboardEvent) => {
      switch (event.code) {
        case "ArrowLeft":
          return handlePrevious();
        case "ArrowRight":
          return handleNext();
        case "KeyA":
          return !currJobLoading && handleApply();
        case "KeyS":
          return !currJobLoading && handleSave();
        default:
          return null;
      }
    };

    if (canUseKeyboardShortcuts && !showDidYouApplyModal && !showApplyModal) {
      window.addEventListener("keyup", keyupListener);

      return () => {
        window.removeEventListener("keyup", keyupListener);
      };
    }
  }, [
    handleNext,
    handlePrevious,
    handleSave,
    handleApply,
    showApplyModal,
    showDidYouApplyModal,
    currJobLoading,
    canUseKeyboardShortcuts,
  ]);

  const enableKeyboardShortcuts = () => {
    if (!canUseKeyboardShortcuts) {
      setCanUseKeyboardShortcuts(true);
    }
  };

  const disableKeyboardShortcuts = () => {
    if (canUseKeyboardShortcuts) {
      setCanUseKeyboardShortcuts(false);
    }
  };

  const toggleKeyboardShortcuts = (enable: boolean) => {
    if (enable) {
      enableKeyboardShortcuts();
    } else {
      disableKeyboardShortcuts();
    }
  };

  const onDragEnd = useCallback(
    (_event: unknown, { offset, velocity }: PanInfo) => {
      const swipe = swipePower(offset.x, velocity.x);
      if (swipe < -SWIPE_THRESHOLD) {
        handleNext();
      } else if (swipe > SWIPE_THRESHOLD) {
        handlePrevious();
      }
    },
    [handleNext, handlePrevious]
  );

  const variants = useMemo(() => {
    return {
      enter: (direction: number) => ({
        x: direction > 0 ? "100%" : "-100%",
        opacity: 0,
      }),
      center: {
        x: 0,
        opacity: 1,
      },
      exit: (direction: number) => ({
        x: direction < 0 ? "100%" : "-100%",
        opacity: 0,
        transition: isTablet
          ? undefined
          : {
              x: { ease: "linear", duration: 0.1 },
              opacity: { duration: 0.1 },
            },
      }),
    };
  }, [isTablet]);

  useEffect(() => {
    if (qs.get("save") === "true" && jobData?.publicJob) {
      save(jobData.publicJob);
      qs.delete("save");
    }
  }, [save, qs, jobData?.publicJob]);

  const lastClickedJobId =
    lastUnconfirmedJobApplication?.currentUser?.lastUnconfirmedJobApplication;
  useEffect(() => {
    if (lastClickedJobId && lastClickedJobId !== jobId) {
      setShowDidYouApplyModal(true);
    }
  }, [jobId, lastClickedJobId]);

  // you can only go backwards back into the job deck from the batch end card
  useEffect(() => (isBatchEnd ? setDirection(-1) : undefined), [isBatchEnd]);

  const isSaved = !!jobData?.publicJob?.latestReaction?.direction;
  const isApplied = !!jobData?.publicJob?.applied;
  const isLive = !!jobData?.publicJob?.live;
  const showJobCardNav = index < jobs.length;

  return (
    <>
      <PageContainer batchEnd={isBatchEnd}>
        <MaybeMiddle batchEnd={isBatchEnd} version2>
          <AnimatePresence>
            {showBatchEndTop && (
              <BatchEndTop version2 params={params} token={token} />
            )}
          </AnimatePresence>

          {!isBatchEnd && (
            <>
              {themeName && themeId && (
                <ThemeBatchHeading
                  version2
                  themeId={themeId}
                  themeName={themeName}
                />
              )}
              <PageProgressContainer
                layout="position"
                data-testid="top-progress-container"
              >
                <PageProgressIndicatorV2
                  total={jobs.length + 1}
                  current={index + 1}
                  progress={progress + 1}
                />
              </PageProgressContainer>
            </>
          )}

          <AnimatePresence
            custom={direction}
            mode={isTablet ? "popLayout" : "wait"}
          >
            {!currJobLoading && !userLoading && (
              <motion.div
                key={currJob || index}
                custom={direction}
                variants={variants}
                initial="enter"
                animate="center"
                exit="exit"
                transition={transition}
                dragDirectionLock
                drag={isTablet || isBatchEnd ? undefined : "x"}
                dragPropagation
                dragSnapToOrigin
                dragConstraints={{
                  left: 0,
                  right: 0,
                }}
                dragElastic={1}
                onDragEnd={onDragEnd}
              >
                {currJob && (
                  <JobCard
                    job={jobData?.publicJob ?? undefined}
                    user={userData?.currentUser ?? undefined}
                    type={JobCardType.jobDeck}
                    toggleKeyboardShortcuts={toggleKeyboardShortcuts}
                  />
                )}
                {index === jobs.length && (
                  <BatchEnd
                    previousJob={prevJob}
                    jobCount={jobs.length + 1}
                    params={params}
                    token={token}
                  />
                )}
              </motion.div>
            )}
          </AnimatePresence>
          {showJobCardNav && (
            <JobCardNav
              onApply={handleApply}
              onSave={handleSave}
              onNext={handleNext}
              onPrevious={handlePrevious}
              isSaved={isSaved}
              isApplied={isApplied}
              showApplyButton={isLive}
              showPrevArrow={!!prevJob && !isBatchEnd}
              showKeyboardHints={!hideKeyboardShortcuts}
            />
          )}
        </MaybeMiddle>
      </PageContainer>
      {showApplyModal && currJob && (
        <ApplyModal
          jobExternalId={currJob}
          type="JOB_DECK"
          token={token}
          onClose={() => setShowApplyModal(false)}
        />
      )}
      {showDidYouApplyModal && lastClickedJobId && (
        <DidYouApplyModal
          jobExternalId={lastClickedJobId}
          token={token}
          onClose={() => {
            setShowDidYouApplyModal(false);
          }}
        />
      )}
      <DragGuideOverlay />
    </>
  );
}
