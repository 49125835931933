import styled, { css, up } from "@xstyled/styled-components";

import { Button, Modal, Spacing, Text } from "@otta/design";
import { palette, pxToRem } from "@otta/design-tokens";
import { IconButton } from "@otta/search/components/IconButton";
import { Delete } from "@otta/search/components/Icons/Delete";
import { useHasNewLogo } from "@otta/search/components/Logo";

const StyledModal = styled(Modal)`
  padding: 3xl xl;

  ${up(
    "tablet",
    css`
      padding: 3xl;
    `
  )}
`;

const GraphicWrapper = styled.div`
  position: absolute;
  left: 0;
  z-index: -1;
  display: flex;
  align-items: center;
  flex-direction: row;
  max-height: 100%;
  width: 100%;
`;

const BackgroundGraphic = styled.video`
  height: 100%;
  width: 100%;
  transform: scale(1.5);

  ${up(
    "tablet",
    css`
      transform: scale(1.1);
    `
  )}
`;

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: ${pxToRem(10)};
  right: ${pxToRem(10)};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: xl;
  border-radius: ${pxToRem(12)};
  background-color: white;
`;

export function ApplyTipsModal({
  onClose,
}: {
  onClose: () => void;
}): React.ReactElement {
  const hasNewLogo = useHasNewLogo();

  return (
    <StyledModal
      open
      onOpenChange={isOpen => {
        if (!isOpen) {
          onClose();
        }
      }}
      dismissable={false}
    >
      {!hasNewLogo && (
        <GraphicWrapper>
          <BackgroundGraphic autoPlay loop muted playsInline>
            <source
              type="video/webm"
              src="https://static.otta.com/videos/Otta_Icons_Amplify-Set_2_compressed.webm"
            />
          </BackgroundGraphic>
        </GraphicWrapper>
      )}
      <StyledIconButton
        colour={palette.brand.black}
        hoverColour={palette.grayscale.shade600}
        data-testid="modal-remove-button"
        onClick={onClose}
      >
        <Delete width={24} />
      </StyledIconButton>
      <Container>
        <Spacing size={2.25}>
          <Spacing>
            <Text align="center" size={2} bold>
              We&apos;re here to help you write better applications
            </Text>
            <Text align="center">
              Now you can easily see role and company details along with
              application tips as you write your applications.
            </Text>
          </Spacing>
          <Button level="primary" onClick={onClose}>
            Check it out now
          </Button>
        </Spacing>
      </Container>
    </StyledModal>
  );
}
