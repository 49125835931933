import { useState } from "react";
import styled from "@xstyled/styled-components";

import { Collapser } from "./Collapser";
import { Star } from "./icons/Star";

import { palette } from "@otta/design-tokens";

const contentPadding = 10;

const AccordionSectionContainer = styled.div<{
  nested: boolean;
}>`
  display: flex;
  flex-direction: column;
  margin: ${({ nested }) => (nested ? `0 -${contentPadding}px 0` : "0")};
`;

const BoxTitle = styled.div`
  width: 100%;
  margin: 0;
  font-size: 16px;
  font-weight: bold;
`;

const BoxTitleRow = styled.div<{
  isStartSection: boolean;
  isEndSection: boolean;
  isOpen: boolean;
  nested: boolean;
  light: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  padding: 8px 10px;
  cursor: pointer;
  color: black;
  background: ${({ nested }) =>
    nested ? "transparent" : palette.extended.yellow.shade100};
  &:hover {
    background: ${({ nested }) =>
      nested ? palette.extended.yellow.shade100 : palette.brand.yellow};
  }
  border-bottom: ${({ isEndSection, isOpen, nested }) =>
      !isOpen && !isEndSection && !nested ? "1px" : "0"}
    solid ${palette.extended.yellow.shade100};
  border-radius: ${({ isStartSection, isEndSection, isOpen, nested }) => {
    if (nested) {
      return "0";
    } else {
      return `${isStartSection ? "4px 4px" : "0 0"} ${
        isEndSection && !isOpen ? "4px 4px" : "0 0"
      }`;
    }
  }};
`;

const BoxBody = styled.div<{
  isEndSection: boolean;
  nested: boolean;
  light: boolean;
  noPadding: boolean;
}>`
  padding: ${({ noPadding }) => (noPadding ? "0" : "6px")} ${contentPadding}px;
  border-radius: ${({ isEndSection }) => (isEndSection ? "0 0 4px 4px" : "0")};
  font-size: 16px;
  background: ${palette.extended.yellow.shade100};
`;

const IconWrapper = styled.div`
  svg {
    width: 14px;
    max-height: 14px;
    display: block;
  }
  width: 14px;
  max-height: 14px;
  margin-right: 7px;
  vertical-align: middle;
`;

interface IAccordionSectionProps {
  title: string;
  children: React.ReactNode;
  index: number;
  totalSections: number;
  nested: boolean;
  light: boolean;
  isOpenAtStart?: boolean;
  noPadding?: boolean;
}

export function AccordionSection({
  title,
  children,
  isOpenAtStart = false,
  noPadding = false,
  index,
  totalSections,
  nested,
  light,
}: IAccordionSectionProps): React.ReactElement {
  const [isOpen, setIsOpen] = useState(isOpenAtStart);

  const isStartSection = index === 0;
  const isEndSection = index === totalSections - 1;

  return (
    <AccordionSectionContainer nested={nested}>
      <BoxTitleRow
        isStartSection={isStartSection}
        isEndSection={isEndSection}
        isOpen={isOpen}
        nested={nested}
        light={light}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        {!nested && (
          <IconWrapper>
            <Star colour={palette.brand.grey} />
          </IconWrapper>
        )}
        <BoxTitle>{title}</BoxTitle>
        <Collapser isOpen={isOpen} />
      </BoxTitleRow>
      {isOpen && (
        <BoxBody
          light={light}
          nested={nested}
          isEndSection={isEndSection}
          noPadding={noPadding}
        >
          {children}
        </BoxBody>
      )}
    </AccordionSectionContainer>
  );
}
