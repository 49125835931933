import { useMemo } from "react";
import styled, { css } from "@xstyled/styled-components";

import { borderRadiusSmall, palette, pxToRem } from "@otta/design-tokens";
import {
  CompanyProfileLinkFragment,
  Job,
  TeamMemberRole,
} from "@otta/search/schema";
import { Text } from "@otta/design";
import { useHasEBContent } from "@otta/search/hooks/useHasEBContent";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: lg;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: xl;
  padding: xl;
  border-radius: ${borderRadiusSmall}px;
  background-color: gray-50;
`;

type OrgChartProps = {
  members: NonNullable<Pick<Job.Fragment, "teamMembers">["teamMembers"]>;
  jobTitle: NonNullable<Pick<Job.Fragment, "title">["title"]>;
  teamSize: number | null;
};

export function OrgChart({ members, jobTitle, teamSize }: OrgChartProps) {
  const hasEBContent = useHasEBContent();

  const { manager, reports } = useMemo(() => {
    const manager = members.find(
      member => member.role === TeamMemberRole.Manager
    );
    const reports = members.filter(
      member => member.role === TeamMemberRole.Report
    );
    return { manager, reports };
  }, [members]);

  if (!hasEBContent || members.length === 0) return null;

  const numberOfOtherTeamMembers = teamSize ? teamSize - members.length : 0;

  return (
    <Wrapper>
      <Text bold size={1}>
        Your team
      </Text>
      <Content>
        {manager && (
          <Member
            role={manager.role}
            name={manager.member.name}
            jobTitle={manager.member.jobTitle}
            assetLink={manager.member.avatarLinks[0]}
          />
        )}

        <Member name="Open role" jobTitle={jobTitle} />

        {reports.map(report => (
          <Member
            key={report.id}
            role={report.role}
            name={report.member.name}
            jobTitle={report.member.jobTitle}
            assetLink={report.member.avatarLinks[0]}
          />
        ))}

        {numberOfOtherTeamMembers > 0 && (
          <Text size={-1} color={palette.grayscale.shade600} align="center">
            +{numberOfOtherTeamMembers} more
          </Text>
        )}
      </Content>
    </Wrapper>
  );
}

const MemberWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: sm;
  width: ${pxToRem(240)};
`;

const avatarStyle = css`
  flex-shrink: 0;
  width: ${pxToRem(64)};
  height: ${pxToRem(64)};
  border-radius: 50%;
`;

const Avatar = styled.img`
  ${avatarStyle};
`;

const PlaceholderAvatar = styled.div`
  ${avatarStyle};
  position: relative;
  background-color: yellow-500;
  overflow: hidden;

  &::before,
  &::after {
    content: "";
    position: absolute;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50%;
    background-color: yellow-100;
  }

  &::before {
    top: ${pxToRem(12)};
    width: ${pxToRem(32)};
    height: ${pxToRem(32)};
  }

  &::after {
    top: ${pxToRem(49)};
    width: ${pxToRem(76)};
    height: ${pxToRem(76)};
  }

  &[data-role=${TeamMemberRole.Manager}] {
    background-color: green-200;
    &::before,
    &::after {
      background-color: green-100;
    }
  }
  &[data-role=${TeamMemberRole.Report}] {
    background-color: orange-400;
    &::before,
    &::after {
      background-color: orange-100;
    }
  }
`;

const MemberContent = styled.div`
  /* Not flex because flex and text-overflow: ellipsis don't play well together */
  display: grid;
  gap: xs;
`;

const MemberRole = styled(Text).attrs({
  size: -1,
  bold: true,
})`
  justify-self: start;
  padding: xxs xs;
  border-radius: ${pxToRem(4)};
  text-transform: capitalize;

  &[data-role=${TeamMemberRole.Manager}] {
    background-color: green-200;
  }
  &[data-role=${TeamMemberRole.Report}] {
    background-color: orange-200;
  }
`;

const MemberInfo = styled(Text).attrs({
  size: -1,
})`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

function Member({
  role,
  name,
  jobTitle,
  assetLink,
}: {
  role?: TeamMemberRole;
  name: string | null;
  jobTitle: string;
  assetLink?: CompanyProfileLinkFragment;
}) {
  const avatar = assetLink?.desktopPlacement;
  const alt = assetLink?.companyBrandAsset.alt ?? `${name} avatar`;

  return (
    <MemberWrapper>
      {avatar?.source ? (
        <Avatar srcSet={avatar.sourceSet ?? ""} src={avatar.source} alt={alt} />
      ) : (
        <PlaceholderAvatar data-role={role} />
      )}
      <MemberContent>
        {role && <MemberRole data-role={role}>{role.toLowerCase()}</MemberRole>}
        {name && <MemberInfo bold>{name}</MemberInfo>}
        <MemberInfo color={palette.grayscale.shade600}>{jobTitle}</MemberInfo>
      </MemberContent>
    </MemberWrapper>
  );
}
