import styled, { css, up } from "@xstyled/styled-components";
import { useMemo } from "react";

import { GlassdoorAndTrustpilot } from "./GlassdoorAndTrustpilot";
import { Highlights } from "./Highlights";

import { CircularProgress, Text, VerticalSpacing } from "@otta/design";
import { modularScale, palette, pxToRem } from "@otta/design-tokens";
import { Company, Job } from "@otta/search/schema";
import { Icon } from "@otta/icons";

const COLORS = {
  good: palette.extended.green.shade400,
  medium: palette.grayscale.shade400,
  bad: palette.extended.red.shade500,
} as const;

const InsightsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  // to cancel out margin from last row
  margin-bottom: -md;
`;

const InsightContainer = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  margin-bottom: md;
  flex-grow: 1;
  gap: sm;

  > p {
    font-size: ${pxToRem(16)};
  }
`;

const InsightRing = styled.div<{ borderColor: string }>`
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  border: 2px solid ${({ borderColor }) => borderColor};
  font-size: ${modularScale(-1)};
  width: 32px;
  height: 32px;

  > svg {
    width: 16px;
    height: 16px;
  }

  ${up(
    "desktop",
    css`
      margin-left: 0;
      margin-right: 0;
    `
  )}
`;

const IconAbsoluteWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
`;

const IconWrapper = styled.div`
  display: flex;
  height: 16px;
  width: 16px;

  > svg {
    width: 16px;
    height: 16px;
  }
`;

const RelativeContainer = styled.div`
  position: relative;
  height: 32px;
  width: 32px;

  ${up(
    "desktop",
    css`
      margin-right: 0;
    `
  )}
`;

const CircularProgressWrapper = styled.div`
  position: absolute;
  text-align: center;
  height: 35px;
  width: 35px;
  left: -2px;
  top: -2px;

  > svg {
    height: 100%;
  }
`;

const DeskTopBreak = styled.br`
  display: none;
  ${up(
    "desktop",
    css`
      display: inline;
    `
  )}
`;

interface IQuadrantInsight {
  quadrant: 0 | 1 | 2 | 3 | 4;
  description: React.ReactNode;
  icon: React.ReactElement;
  id: string;
}

const getResponseRateContent = (
  rate: number
): { adjective: string; quadrant: 0 | 1 | 2 | 3 | 4 } => {
  if (rate >= 80) {
    return { adjective: "Most", quadrant: 4 };
  } else if (rate >= 60) {
    return { adjective: "Many", quadrant: 3 };
  } else if (rate >= 30) {
    return { adjective: "Some", quadrant: 2 };
  } else {
    return { adjective: "Few", quadrant: 1 };
  }
};

const getResponseRateInsight = (
  responseRate: string | null,
  version2?: boolean
): IQuadrantInsight[] => {
  if (responseRate === null) {
    return [];
  }

  const rate = Math.round(parseFloat(responseRate) * 100);
  const { adjective, quadrant } = getResponseRateContent(rate);

  return [
    {
      quadrant,
      description: (
        <Text size={-1}>
          <strong>{adjective}</strong> candidates hear{" "}
          {!version2 && <DeskTopBreak />}
          back within 2 weeks
        </Text>
      ),
      icon: <Icon icon="response-rate" size={1} />,
      id: "response-rate-insight",
    },
  ];
};

type ValidColor =
  | (typeof COLORS)[keyof typeof COLORS]
  | typeof palette.brand.black;

const getColor = (
  value: number,
  minValues: {
    good: number;
    medium: number;
    bad: number;
  }
): ValidColor => {
  for (const [category, minVal] of Object.entries(minValues)) {
    if (value >= minVal) {
      return COLORS[category as keyof typeof minValues];
    }
  }
  return palette.brand.black;
};

interface IInsight {
  description: React.ReactNode;
  color: ValidColor;
  icon: React.ReactElement;
  id: string;
}

const getEmployeeGrowthInsight = (
  employeeGrowthPercentage: number | null
): IInsight[] => {
  if (employeeGrowthPercentage === null) {
    return [];
  }

  return [
    {
      description: (
        <Text size={-1}>
          <strong>{employeeGrowthPercentage}%</strong> employee growth in 12
          months
        </Text>
      ),
      color: getColor(employeeGrowthPercentage, {
        good: 5,
        medium: -5,
        bad: -100,
      }),
      icon: <Icon icon="chart-up" size={1} />,
      id: "employee-growth-insight",
    },
  ];
};

const getGenderDiversityInsight = (
  percentageFemale: number | null
): IInsight[] => {
  if (percentageFemale === null || percentageFemale < 0) {
    return [];
  }

  return [
    {
      description: (
        <Text size={-1}>
          <strong>{percentageFemale}%</strong> female employees
        </Text>
      ),
      color: getColor(percentageFemale, {
        good: 40,
        medium: 10,
        bad: 0,
      }),
      icon: <Icon icon="female" size={1} />,
      id: "gender-diversity-insight",
    },
  ];
};

const getQuadrantInsights = (
  company: InsightsProps["company"]
): IQuadrantInsight[] => [...getResponseRateInsight(company.responseRate)];

const getInsights = (company: InsightsProps["company"]): IInsight[] => [
  ...getGenderDiversityInsight(company.percentageFemale),
  ...getEmployeeGrowthInsight(company.yearEmployeeGrowthPercentage),
];

const hasInsights = ({
  company,
  insights,
  quadrantInsights,
}: {
  company: InsightsProps["company"];
  insights: IInsight[];
  quadrantInsights: IQuadrantInsight[];
}) =>
  !(
    insights.length + quadrantInsights.length === 0 &&
    !company.glassdoorRating &&
    !company.trustpilotRating
  );

export const shouldDisplayInsights = (company: InsightsProps["company"]) => {
  const insights = getInsights(company);
  const quadrantInsights = getQuadrantInsights(company);

  return hasInsights({
    company,
    insights,
    quadrantInsights,
  });
};

interface InsightsProps {
  job?: Pick<Job.Fragment, "activelyHiring">;
  company: Pick<
    Company.Fragment,
    | "responseRate"
    | "percentageFemale"
    | "yearEmployeeGrowthPercentage"
    | "glassdoorRating"
    | "glassdoorUrl"
    | "trustpilotRating"
    | "trustpilotUrl"
    | "founders"
    | "bcorp"
    | "id"
    | "investors"
  >;
}

export function Insights({
  job,
  company,
}: InsightsProps): React.ReactElement | null {
  const insights = useMemo(() => getInsights(company), [company]);
  const quadrantInsights = useMemo(
    () => getQuadrantInsights(company),
    [company]
  );

  // We're not using shouldDisplayInsights since we would recalculate the insights twice otherwise
  if (
    !hasInsights({
      company,
      insights,
      quadrantInsights,
    })
  ) {
    return null;
  }

  return (
    <VerticalSpacing>
      <Highlights company={company} activelyHiring={job?.activelyHiring} />
      <InsightsContainer data-testid="insights">
        {quadrantInsights.map(insight => (
          <QuadrantInsight key={insight.id} {...insight} />
        ))}
        {insights.map(insight => (
          <Insight key={insight.id} {...insight} />
        ))}
        <GlassdoorAndTrustpilot company={company} />
      </InsightsContainer>
    </VerticalSpacing>
  );
}

function Insight({
  description,
  color,
  icon,
  id,
}: IInsight): React.ReactElement {
  return (
    <InsightContainer data-testid={id}>
      <div>
        <InsightRing borderColor={color}>{icon}</InsightRing>
      </div>
      {description}
    </InsightContainer>
  );
}

function QuadrantInsight({
  description,
  quadrant,
  icon,
  id,
}: IQuadrantInsight): React.ReactElement {
  return (
    <InsightContainer data-testid={id}>
      <RelativeContainer>
        <CircularProgressWrapper>
          <CircularProgress
            quadrants={quadrant}
            strokeWidth={8}
            style={{ width: 35, height: 35 }}
          />
        </CircularProgressWrapper>
        <IconAbsoluteWrapper>
          <IconWrapper>{icon}</IconWrapper>
        </IconAbsoluteWrapper>
      </RelativeContainer>
      {description}
    </InsightContainer>
  );
}
