import { Fragment } from "react";
import styled, { up, css } from "@xstyled/styled-components";
import { useLocation, useNavigate } from "react-router-dom";

import { QuizSection } from "../SidebarQuiz";

import { ScrollableContainer as DefaultScrollableContainer } from "./ScrollableContainer";

import { Button, Middle, Spacing, Text } from "@otta/design";
import { Icon } from "@otta/icons";
import { pxToRem, palette } from "@otta/design-tokens";

const ScrollableContainer = styled(DefaultScrollableContainer)`
  padding: xl lg;
  ${up(
    "desktop",
    css`
      display: none;
    `
  )}
`;

const SectionHeader = styled.div`
  align-items: center;
  display: flex;
  gap: sm;
`;

const Hr = styled.hr<{ colour: string; margin?: number }>`
  background-color: ${({ colour }) => colour};
  border: none;
  height: 1px;
  margin: 0 ${({ margin }) => pxToRem(margin ?? 0)};
`;

const QuestionsContainer = styled.div`
  background-color: white;
  border-radius: ${pxToRem(16)};
  display: flex;
  flex-direction: column;
`;

const Question = styled.div`
  &:first-child {
    border-radius: ${pxToRem(16)} ${pxToRem(16)} 0 0;
  }

  &:last-child {
    border-radius: 0 0 ${pxToRem(16)} ${pxToRem(16)};
  }
  &:hover {
    background-color: gray-50;
  }

  align-items: center;
  background-color: white;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: lg;
  transition: default;
`;

const Section = ({
  title,
  questions,
  icon,
}: Pick<QuizSection, "title" | "icon" | "questions">) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Spacing key={title} size={-4}>
      <SectionHeader>
        <Icon icon={icon} size={2} />
        <Text bold style={{ lineHeight: 1.2 }}>
          {title}
        </Text>
      </SectionHeader>
      <QuestionsContainer>
        {questions.map((q, index) => {
          return (
            <Fragment key={q.path}>
              <Question
                onClick={() =>
                  navigate({
                    pathname: `../${q.path}`,
                    search: location.search,
                  })
                }
              >
                <Text>{q.menuTitle}</Text>
                <Icon icon="chevron-right" />
              </Question>
              {index !== questions.length - 1 && (
                <Hr colour={palette.beige.shade200} margin={16} />
              )}
            </Fragment>
          );
        })}
      </QuestionsContainer>
    </Spacing>
  );
};

interface OverviewProps {
  sections: QuizSection[];
}

export function Overview({ sections }: OverviewProps): React.ReactElement {
  const location = useLocation();
  const navigate = useNavigate();

  const handleReview = () => {
    const pathname = sections[0]?.questions[0].path;

    navigate(
      {
        pathname,
        search: location.search,
      },
      {
        replace: true,
        state: "review-all",
      }
    );
  };

  return (
    <ScrollableContainer data-testid="quiz-overview">
      <Middle maxWidth={394} style={{ textAlign: "left" }}>
        <Spacing size={2.25}>
          <Spacing>
            <Text>
              You can review all of your search preferences or change one at a
              time.
            </Text>
            <Button
              level="secondary"
              onClick={handleReview}
              style={{ textAlign: "center", width: "100%" }}
            >
              Review all search preferences
            </Button>
          </Spacing>
          <Hr colour={palette.grayscale.shade400} />
          <Spacing size={2.25}>
            {sections.map(({ title, icon, questions }) => (
              <Section
                key={title}
                title={title}
                icon={icon}
                questions={questions}
              />
            ))}
          </Spacing>
        </Spacing>
      </Middle>
    </ScrollableContainer>
  );
}
