import { useEffect, useMemo, useState } from "react";
import styled from "@xstyled/styled-components";
import { AnimatePresence, motion } from "framer-motion";

import {
  ApplicationTipsTab,
  CompanyTab,
  RoleTab,
  MeetTheTeamTab,
} from "./PreviewSidebar";

import { ApplicationProps, ApplicationView } from "./";

import { palette, pxToRem } from "@otta/design-tokens";
import { Button, Tabs } from "@otta/design";
import { pushAnalyticsEvent } from "@otta/analytics";
import { Loading } from "@otta/search/components/Loading";
import { NAV_HEIGHT } from "@otta/search/globalConstants";

const WRAPPER_MAX_WIDTH = pxToRem(1072);
const SIDEBAR_WIDTH = pxToRem(582);

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: ${WRAPPER_MAX_WIDTH};
  margin: 0 auto;
`;

const Content = styled.div`
  width: 100%;
  max-width: 100%;
  min-width: ${pxToRem(500)};
  will-change: width;
  transition: default;

  &[data-has-sidebar="true"] {
    /* This will ensure that the content is not being overlapped by the sidebar */
    width: calc(
      min(${WRAPPER_MAX_WIDTH}, 100vw) -
        (
          ${SIDEBAR_WIDTH} -
            calc((100vw - min(${WRAPPER_MAX_WIDTH}, 100vw)) / 2)
        )
    );
  }
`;

const SidebarTrigger = styled(Button).attrs({
  level: "secondary",
  size: "small",
})`
  height: ${pxToRem(32)};
  padding: 0 lg;
`;

const OpenSidebarButton = styled(SidebarTrigger)`
  position: absolute;
  right: ${pxToRem(20)};
  top: ${pxToRem(20)};

  &[aria-hidden="true"] {
    visibility: hidden;
  }
`;

const Sidebar = styled(motion.div).attrs({
  initial: { x: "100%" },
  animate: { x: 0 },
  exit: { x: "100%" },
  transition: { duration: 0.2 },
})`
  position: fixed;
  top: ${NAV_HEIGHT}px;
  right: 0;
  z-index: 1;
  width: ${SIDEBAR_WIDTH};
  background-color: beige-100;
  border-left: 1px solid ${palette.brand.grey};
`;

const TabsContent = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - ${NAV_HEIGHT}px);
  overflow-y: auto;
`;

const TabListWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: lg xl;
  background-color: beige-100;
`;

const TabPanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: xl;
  padding: sm xl xl;
  overflow-y: auto;
`;

export function DesktopApplication({
  job,
  jobId,
  analyticsData,
  shouldShowJobDetails,
}: ApplicationProps) {
  const [showJobDetails, setShowJobDetails] = useState(false);

  useEffect(() => {
    if (shouldShowJobDetails) {
      setShowJobDetails(true);
    }
  }, [shouldShowJobDetails]);

  const onOpenSidebar = () => {
    pushAnalyticsEvent({
      eventName: "Candidate Clicked",
      name: "show-job-details",
      page: "apply-via-otta-application",
      ...analyticsData,
    });
    setShowJobDetails(true);
  };

  const onCloseSidebar = () => {
    pushAnalyticsEvent({
      eventName: "Candidate Clicked",
      name: "close-job-details",
      page: "apply-via-otta-application",
      ...analyticsData,
    });
    setShowJobDetails(false);
  };

  const onClickJobTab = (tab: string) => {
    pushAnalyticsEvent({
      eventName: "Candidate Clicked",
      name: tab,
      page: "apply-via-otta-application",
      ...analyticsData,
    });
  };

  const tabs = useMemo(() => {
    return [
      {
        id: "role",
        title: "Role",
        content: job ? (
          <TabPanelWrapper>
            <RoleTab job={job} />
          </TabPanelWrapper>
        ) : (
          <Loading />
        ),
      },
      {
        id: "company",
        title: "Company",
        content: job ? (
          <TabPanelWrapper>
            <CompanyTab job={job} company={job.company} />
          </TabPanelWrapper>
        ) : (
          <Loading />
        ),
      },
      {
        id: "application-tips",
        title: "Application tips",
        content: job ? (
          <TabPanelWrapper>
            <ApplicationTipsTab />
          </TabPanelWrapper>
        ) : (
          <Loading />
        ),
      },
      ...(job?.team
        ? [
            {
              id: "team",
              title: `Meet the ${job.team.name}`,
              content: (
                <TabPanelWrapper>
                  <MeetTheTeamTab
                    team={job.team}
                    jobTitle={job.title}
                    members={job.teamMembers}
                  />
                </TabPanelWrapper>
              ),
            },
          ]
        : []),
    ];
  }, [job]);

  return (
    <Wrapper>
      <Content data-has-sidebar={showJobDetails}>
        <ApplicationView jobId={jobId} analyticsData={analyticsData} />
        <AnimatePresence>
          {showJobDetails && (
            <Sidebar>
              <Tabs tabs={tabs} onChange={onClickJobTab}>
                <TabsContent>
                  <TabListWrapper>
                    <Tabs.List />
                    <SidebarTrigger onClick={onCloseSidebar}>
                      Close
                    </SidebarTrigger>
                  </TabListWrapper>
                  <Tabs.Panels />
                </TabsContent>
              </Tabs>
            </Sidebar>
          )}
        </AnimatePresence>
      </Content>

      <OpenSidebarButton onClick={onOpenSidebar} aria-hidden={showJobDetails}>
        Show job details
      </OpenSidebarButton>
    </Wrapper>
  );
}
