import styled from "@xstyled/styled-components";
import { useEffect, useState, useCallback, useMemo, useRef } from "react";
import { useIsPresent } from "framer-motion";

import { QuestionComponentProps } from "..";
import { GenderSalaryNudge } from "../components/GenderSalaryNudge";

import { determineCurrency } from "./utils";

import {
  palette,
  modularScale,
  pxToRem,
  typography,
} from "@otta/design-tokens";
import { Spacing, List, Clickable } from "@otta/design";
import { Loading } from "@otta/search/components/Loading";
import { Currency } from "@otta/search/schema";
import { CircularSlider } from "@otta/search/components/Slider/CircularSlider";
import { currencyPrefix } from "@otta/search/utils/currencies";
import { useUserPreferences } from "@otta/search/providers/UserPreferencesProvider/useUserPreferences";

const SALARIES = (() => {
  const options: string[] = [];
  let currentVal = 0;
  for (let i = 0; i < 85; i++) {
    if (currentVal === 0) {
      currentVal = 10;
    } else if (currentVal < 40) {
      currentVal += 1;
    } else if (currentVal < 80) {
      currentVal += 2;
    } else if (currentVal < 150) {
      currentVal += 5;
    } else if (currentVal < 250) {
      currentVal += 10;
    } else {
      currentVal += 25;
    }
    options.push(currentVal.toString());
  }
  return options;
})();

const labelToAmount = (label: string): string => {
  return label + "000";
};

const SalaryWrapper = styled.div`
  position: relative;
  code {
    font-family: ${typography.family.heading.name};
  }
`;

const ButtonContainer = styled.div`
  margin-top: 20;
  z-index: 100;
`;

const CurrencyButton = styled(Clickable)<{ $isActive?: boolean }>`
  width: ${pxToRem(80)};
  background-color: white;
  padding: xs 0;
  -webkit-tap-highlight-color: transparent;
  border-top: 1px solid ${palette.grayscale.shade400};
  border-bottom: 1px solid ${palette.grayscale.shade400};
  border-left: 1px solid ${palette.grayscale.shade400};
  border-radius: 0px;
  &:hover {
    color: black;
    font-weight: bold;
  }
  color: ${({ $isActive }) =>
    $isActive ? palette.brand.black : palette.grayscale.shade400};
  font-weight: ${({ $isActive }) => ($isActive ? "bold" : "normal")};
  font-size: ${modularScale(-1)};

  &:first-of-type {
    border-left: 1px solid ${palette.grayscale.shade400};
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  &:last-of-type {
    border-right: 1px solid ${palette.grayscale.shade400};
    border-left: 1px solid ${palette.grayscale.shade400};
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

interface Money {
  amount: string;
  currency: Currency;
}

interface CurrencyType {
  label: string;
  currency: Currency;
}
interface ISalaryPreferenceComponentProps {
  salaryRequirement: Money;
  onChange: (input: Money) => void;
}

const Currencies: CurrencyType[] = [
  { label: "USD ($)", currency: Currency.Usd },
  { label: "CAD ($)", currency: Currency.Cad },
  { label: "GBP (£)", currency: Currency.Gbp },
  { label: "EUR (€)", currency: Currency.Eur },
];

const reorderCurrencies = (
  currencies: CurrencyType[],
  topCurrency: Currency
) => {
  currencies.forEach((x, i) => {
    if (x.currency === topCurrency) {
      currencies.splice(i, 1);
      currencies.unshift(x);
    }
  });
  return currencies;
};

export function SalaryPreferenceComponent({
  salaryRequirement,
  onChange,
}: ISalaryPreferenceComponentProps): React.ReactElement {
  const initial = useRef(salaryRequirement).current;

  const [currSalary, setCurrSalary] = useState(salaryRequirement);

  const timer = useRef<number>();

  const debounceChange = useCallback(
    (value: Money) => {
      window.clearTimeout(timer.current);

      setCurrSalary(value);
      timer.current = window.setTimeout(() => {
        onChange(value);
      }, 100);
    },
    [onChange]
  );

  const handleAmountChange = (label: string) => {
    debounceChange({
      ...currSalary,
      amount: labelToAmount(label),
    });
  };

  const handleCurrencyChange = (newCurrency: Currency) => {
    debounceChange({
      ...currSalary,
      currency: newCurrency,
    });
  };

  const initialIndex = SALARIES.findIndex(
    label => labelToAmount(label) === initial.amount
  );

  const orderedCurrencies = useMemo(
    () => reorderCurrencies(Currencies, initial.currency),
    [initial.currency]
  );

  return (
    <>
      <SalaryWrapper>
        <CircularSlider
          width={270}
          label="Minimum"
          prependToValue={
            currSalary.currency == Currency.Cad
              ? "$"
              : currencyPrefix(currSalary.currency)
          }
          appendToValue="k"
          data={SALARIES}
          dataIndex={initialIndex}
          onChange={handleAmountChange}
        />
      </SalaryWrapper>
      <ButtonContainer>
        {orderedCurrencies.map(c => (
          <CurrencyButton
            $isActive={currSalary.currency == c.currency}
            onClick={() => {
              handleCurrencyChange(c.currency);
            }}
            key={c.label}
          >
            {c.label}
          </CurrencyButton>
        ))}
      </ButtonContainer>
    </>
  );
}

export function SalaryPreference({
  setNextEnabled,
}: QuestionComponentProps): React.ReactElement {
  const { preferencesData, updatePreferences } = useUserPreferences();
  const currency = useMemo(() => {
    const locations = (preferencesData?.locationPreferences ?? []).map(
      l => l.location
    );
    return (
      preferencesData?.minSalaryRequirement?.currency ??
      determineCurrency(locations)
    );
  }, [
    preferencesData?.locationPreferences,
    preferencesData?.minSalaryRequirement?.currency,
  ]);

  const isPresent = useIsPresent();

  useEffect(() => {
    if (isPresent) {
      setNextEnabled(!!preferencesData?.minSalaryRequirement);
    }
  }, [preferencesData, setNextEnabled, isPresent]);

  const updateCurrentUser = useCallback(
    (input: Money) => {
      updatePreferences({
        minSalaryRequirement: input,
      });
    },
    [updatePreferences]
  );

  if (!preferencesData) {
    return <Loading />;
  }

  const amount = preferencesData.minSalaryRequirement?.amount ?? "25000";
  const genders = preferencesData.genders ?? [];

  return (
    <Spacing size={2}>
      <SalaryPreferenceComponent
        salaryRequirement={{ amount, currency }}
        onChange={updateCurrentUser}
      />
      <List type="positive">
        <li>We never share this with companies</li>
        <li>We only use this to filter out roles and save you time</li>
        <li>
          If you&apos;re unsure, we recommend choosing a lower amount so you
          don&apos;t miss out on roles that could be great
        </li>
      </List>
      {genders.length > 0 && <GenderSalaryNudge genders={genders} />}
    </Spacing>
  );
}
