import styled from "@xstyled/styled-components";

import { palette, pxToRem } from "@otta/design-tokens";
import { Icon } from "@otta/icons";
import { Middle } from "@otta/design";

const StyledFooter = styled.footer`
  background: ${palette.brand.yellow};
  color: black;
  padding: lg;
  position: relative;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: lg;
`;

const LargeIcon = styled(Icon)`
  font-size: ${pxToRem(32)};
  color: black;
`;

export function Footer(): React.ReactElement {
  return (
    <StyledFooter>
      <Middle>
        <IconContainer>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.linkedin.com/company/otta-uk"
          >
            <LargeIcon icon="linkedin" />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://instagram.com/ottahq"
          >
            <LargeIcon icon="instagram" />
          </a>
        </IconContainer>
      </Middle>
    </StyledFooter>
  );
}
