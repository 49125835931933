import { AssetGroupSprinkler } from "../AssetSprinkler";

import { Spacing, Text } from "@otta/design";
import { BrandAssetGroup, Company } from "@otta/search/schema";
import { Profile } from "@otta/search/components/Profile";
import { Separator } from "@otta/search/components/Separator";

export const companyHasFounders = (
  company: Pick<Company.Company, "founders">
) => company.founders.length > 0;
export const companyHasPeopleProgressing = (
  company: Pick<Company.Company, "peopleProgressing">
) => company.peopleProgressing.length > 0;

// Not used internally because of the differences between the job card and company profile
// job card: we show both next to each other with a separator: see <PeopleAtCompany />
// company profile: we show both if their respective <Card>
export const shouldDisplayPeopleAtCompany = (company: Company.Company) => {
  return companyHasFounders(company) || companyHasPeopleProgressing(company);
};

export const Founders = ({
  company,
}: {
  company: Pick<Company.Company, "founders" | "brandAssetGroups">;
}) => {
  if (!companyHasFounders(company)) {
    return null;
  }
  return (
    <div data-testid="founders">
      <AssetGroupSprinkler
        assets={company.brandAssetGroups}
        group={BrandAssetGroup.CompanyProfileFounders}
        noTitles
      >
        <Spacing>
          <Text bold size={1}>
            Leadership
          </Text>
          <Spacing>
            {company.founders.map(
              ({ id, name, title: personTitle, linkedinUrl, bio }) => (
                <Profile
                  key={id}
                  name={name}
                  title={personTitle}
                  linkedIn={linkedinUrl}
                  bio={bio}
                />
              )
            )}
          </Spacing>
        </Spacing>
      </AssetGroupSprinkler>
    </div>
  );
};

export const PeopleProgressing = ({
  company,
}: {
  company: Pick<Company.Company, "brandAssetGroups" | "peopleProgressing">;
}) => {
  if (!companyHasPeopleProgressing(company)) {
    return null;
  }

  return (
    <AssetGroupSprinkler
      assets={company.brandAssetGroups}
      group={BrandAssetGroup.CompanyProfilePeopleProgressing}
      data-testid="people-progressing"
      noTitles
    >
      <Spacing>
        <Text bold as="h3" size={1}>
          People progressing
        </Text>
        {company.peopleProgressing.map(({ id, name, linkedinUrl, bio }) => (
          <Profile key={id} name={name} linkedIn={linkedinUrl} bio={bio} />
        ))}
      </Spacing>
    </AssetGroupSprinkler>
  );
};

export const PeopleAtCompany = ({ company }: { company: Company.Company }) => {
  const hasFounders = companyHasFounders(company);
  const hasPeopleProgressing = companyHasPeopleProgressing(company);

  if (!hasFounders && !hasPeopleProgressing) {
    return null;
  }

  return (
    <>
      {hasFounders && <Founders company={company} />}
      {hasFounders && hasPeopleProgressing && <Separator />}
      {hasPeopleProgressing && <PeopleProgressing company={company} />}
    </>
  );
};
