import { useEffect } from "react";
import styled, { css, up } from "@xstyled/styled-components";

import { Heading, Modal, Spacing, Text } from "@otta/design";
import { palette, pxToRem } from "@otta/design-tokens";
import { pushAnalyticsEvent } from "@otta/analytics";
import { IconButton } from "@otta/search/components/IconButton";
import { Delete } from "@otta/search/components/Icons/Delete";
import { Link } from "@otta/search/components/Link";
import { useWelcomeToTheJungle } from "@otta/search/hooks/useWelcomeToTheJungle";
import { useHasNewLogo } from "@otta/search/components/Logo";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border-radius: ${pxToRem(12)};
  background-color: white;

  margin: 3xl lg;
  padding: xl lg;
  height: ${pxToRem(235)};

  bottom: -${pxToRem(8)};

  position: relative;
  z-index: 1;

  ${up(
    "tablet",
    css`
      margin: 3xl;
      padding: xl 98;
      height: ${pxToRem(235)};
    `
  )}
`;

const Image = styled.img<{ size: number; margin: number }>`
  align-items: center;
  height: ${({ size }) => pxToRem(size)};
  margin: 0 ${({ margin }) => pxToRem(margin)};
`;

const StoreButtons = styled.div`
  align-items: center;
  display: flex;
  gap: md;
  max-height: ${pxToRem(40)};
`;

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: ${pxToRem(10)};
  right: ${pxToRem(10)};
`;

const BackgroundGraphic = styled.video`
  height: 100%;
  width: 100%;

  transform: scale(1.5);

  ${up(
    "tablet",
    css`
      transform: scale(1.1);
    `
  )}
`;

const GraphicWrapper = styled.div`
  align-items: center;
  border-radius: ${pxToRem(12)};
  display: flex;
  flex-direction: row;
  max-height: 100%;
  min-width: 100%;
  overflow: hidden;
  position: absolute;
`;

const AppButtonsSection = ({
  handleClose,
}: AppModalProps): React.ReactElement => (
  <StoreButtons>
    <Link
      to="https://apps.apple.com/app/apple-store/id1640509194?pt=124097318&ct=job_card_modal&mt=8"
      data-analytics-id="download-on-app-store"
      onClick={handleClose}
      data-testid="apple-button-link"
    >
      <Image
        src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83"
        alt="Download on the App Store"
        size={40}
        margin={0}
      />
    </Link>
    <Link
      to="https://play.google.com/store/apps/details?id=com.otta.otta&utm_source=job_card_modal"
      data-analytics-id="get-it-on-google-play"
      onClick={handleClose}
      data-testid="google-button-link"
    >
      <Image
        alt="Get it on Google Play"
        src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
        size={58}
        margin={-10}
      />
    </Link>
  </StoreButtons>
);

interface AppModalProps {
  handleClose: () => void;
}

export function AppModal({
  handleClose,
}: AppModalProps): React.ReactElement | null {
  const hasNewLogo = useHasNewLogo();

  useEffect(() => {
    pushAnalyticsEvent({
      eventName: "Candidate Viewed",
      name: "Get the app modal",
    });
  }, []);

  const companyName = useWelcomeToTheJungle();

  const webmUrl =
    "https://static.otta.com/videos/Otta_Icons_Amplify-Set_2_compressed.webm";

  return (
    <Modal
      open
      onOpenChange={o => {
        if (!o) {
          handleClose();
        }
      }}
      dismissable={false}
    >
      {!hasNewLogo && (
        <GraphicWrapper>
          <BackgroundGraphic autoPlay loop muted playsInline>
            <source type="video/webm" src={webmUrl} />
          </BackgroundGraphic>
        </GraphicWrapper>
      )}
      <StyledIconButton
        colour={palette.brand.black}
        hoverColour={palette.grayscale.shade600}
        data-testid="modal-remove-button"
        onClick={handleClose}
      >
        <Delete width={20} />
      </StyledIconButton>
      <Container>
        <Spacing>
          <Heading align="center" size={2} bold>
            Get the app
          </Heading>
          <Text align="center">
            The {companyName} you love at the tap of a button. Search jobs on
            the go. Never miss a match with our new job alerts.
          </Text>
          <AppButtonsSection handleClose={handleClose} />
        </Spacing>
      </Container>
    </Modal>
  );
}
