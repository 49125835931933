import styled, { css, down } from "@xstyled/styled-components";
import { AnimatePresence, motion } from "framer-motion";

import { ProgressBanner } from "./components/ProgressBanner";

import { QuizLayoutProps } from ".";

import { Spacing, Overline, Middle, Text } from "@otta/design";
import { pxToRem, typography, modularScale } from "@otta/design-tokens";
import { Clock as ClockIcon } from "@otta/search/components/Icons/Clock";

const Gutter = styled.div`
  padding: 0 md ${modularScale(9)} md;
`;

const Title = styled.div`
  font-size: ${modularScale(2)};
  line-height: ${modularScale(3)};
  font-weight: 700;
  font-family: ${typography.family.heading.name};

  ${down(
    "tablet",
    css`
      font-size: ${pxToRem(19)};
      line-height: ${pxToRem(28)};
    `
  )}
`;

const Subtitle = styled.div`
  font-size: ${modularScale(1)};
  line-height: ${modularScale(2)};

  @media (max-width: 500px) {
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(29)};
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background-color: beige-200;
  min-height: ${pxToRem(100)};
  padding: 28;
`;

const QuizWrapper = styled.div`
  position: relative;
  overflow-x: hidden;
  background-color: beige-200;
  flex: 1;
  padding-bottom: xxl;
`;

const StyledMiddle = styled(Middle)`
  max-width: ${pxToRem(500)};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: sm xs;
`;

const Clock = styled(ClockIcon)`
  width: ${pxToRem(15)};
  height: ${pxToRem(15)};
  margin-right: 6;
  transform: scale(-1, 1);
`;

const variants = {
  initial: (direction: number) => ({
    x: direction > 0 ? 1000 : -1000,
    opacity: 0,
  }),
  animate: {
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => ({
    x: direction < 0 ? 1000 : -1000,
    opacity: 0,
  }),
};

const transition = {
  type: "spring",
  stiffness: 300,
  damping: 40,
};

const BannerContent = (): React.ReactElement => (
  <ContentWrapper>
    <Clock />
    <Text bold size={-1} align="center">
      Your best matches are 3 minutes away!
    </Text>
  </ContentWrapper>
);

export function AnimatedQuizLayout({
  question,
  progress,
  customHeaderText,
  buttons,
  progressBanner,
  direction,
  firstQuestion,
  nextEnabled,
  setNextEnabled,
  handleNext,
  handleSkip,
}: QuizLayoutProps & {
  progressBanner?: boolean;
  direction: number;
  firstQuestion: boolean;
}): React.ReactElement {
  const initialPageLoad = firstQuestion && direction > 0;

  const QuestionComponent = question.component;

  return (
    <>
      {progressBanner && (
        <ProgressBanner showBanner={!!initialPageLoad} progress={progress}>
          <BannerContent />
        </ProgressBanner>
      )}
      <QuizWrapper>
        {customHeaderText && (
          <Overline as="h6" size={-1}>
            {customHeaderText}
          </Overline>
        )}
        <Spacing size={2}>
          <Header>
            <AnimatePresence mode="popLayout" custom={direction}>
              <motion.div
                key={question.path}
                custom={direction}
                variants={variants}
                transition={transition}
                initial="initial"
                animate="animate"
                exit="exit"
              >
                <Title>{question.title}</Title>
              </motion.div>
            </AnimatePresence>
          </Header>
          <Gutter>
            <StyledMiddle maxWidth={450}>
              <AnimatePresence mode="popLayout" custom={direction}>
                <motion.div
                  key={question.path}
                  custom={direction}
                  variants={variants}
                  transition={transition}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                >
                  <Spacing size={2}>
                    {question.subtitle && (
                      <Subtitle>{question.subtitle}</Subtitle>
                    )}
                    <QuestionComponent
                      handleNext={handleNext}
                      nextEnabled={nextEnabled}
                      setNextEnabled={setNextEnabled}
                      handleSkip={handleSkip}
                    />
                  </Spacing>
                </motion.div>
              </AnimatePresence>
            </StyledMiddle>
          </Gutter>
        </Spacing>
      </QuizWrapper>
      {buttons}
    </>
  );
}
