import { useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import styled from "@xstyled/styled-components";
import qs from "query-string";

import { LoginForm } from "./Form";

import { Heading, Spacing, Text } from "@otta/design";
import { modularScale } from "@otta/design-tokens";
import { pushAnalyticsEvent } from "@otta/analytics";
import { ExternalSignIn } from "@otta/search/components/ExternalSignIn";
import { useWelcomeToTheJungle } from "@otta/search/hooks/useWelcomeToTheJungle";

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 390px;
  margin: 0 auto;
  width: 100%;
  padding: ${modularScale(4.5)} lg lg;

  > div {
    width: 100%;
  }
`;

const StyledLink = styled(Link)`
  color: black;
  text-decoration: underline;
`;

export function Login(): React.ReactElement {
  const location = useLocation();
  const companyName = useWelcomeToTheJungle();

  useEffect(() => {
    pushAnalyticsEvent({ eventName: "Candidate Login Page Viewed" });
  }, []);

  const { utm_source: parsedUtmSource } = qs.parse(location.search);

  const utmSource =
    typeof parsedUtmSource === "string" ? parsedUtmSource : undefined;

  return (
    <>
      <Helmet>
        <title>{companyName} | Login</title>
        <link rel="canonical" href="https://app.otta.com/login" />
      </Helmet>
      <SectionWrapper>
        <Spacing size={2.25}>
          <Heading size={2} align="center" data-testid="login-heading">
            Sign in
          </Heading>
          <LoginForm />
          <Spacing size={5}>
            <ExternalSignIn utmSource={utmSource} />
            <Text align="center">
              Don&apos;t have an account?{" "}
              <StyledLink
                to={{ pathname: "/sign-up", search: location.search }}
              >
                Sign up
              </StyledLink>
            </Text>
          </Spacing>
        </Spacing>
      </SectionWrapper>
    </>
  );
}
