import styled, { css } from "@xstyled/styled-components";

import { pulse } from "@otta/design";
import {
  pxToRem,
  borderRadius,
  modularScale,
  palette,
} from "@otta/design-tokens";

export const TagsContainer = styled.div<{ alignLeft?: boolean }>`
  justify-content: ${({ alignLeft }) => (alignLeft ? "flex-start" : "center")};
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const Tag = styled.div<{
  disabled?: boolean;
  backgroundColor?: string;
  bold?: boolean;
  small?: boolean;
  size?: number;
}>`
  display: flex;
  cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};
  background-color: ${({ backgroundColor = palette.brand.white }) =>
    backgroundColor};
  ${({ bold }) => bold && `font-weight: 700`};
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  color: black;
  border-radius: ${pxToRem(borderRadius)};
  font-size: ${({ size }) => (size ? modularScale(size) : modularScale(-1))};
  padding: sm;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ small }) =>
    small
      ? css`
          padding: sm;
          margin: 0 6 6 0;
        `
      : css`
          padding: 9;
          margin: 0 sm sm 0;
        `};

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover {
        animation: ${pulse} 0.2s ease-out;
      }
    `}
`;
